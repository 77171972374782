import React, { useState, useEffect, useContext } from 'react'
import styles from './AboutPage.module.scss'
import TwoSidedPage from '../../components/pages-layouts/two-sided-page/TwoSidedPage'
import { useTranslation } from 'react-i18next';
import { formatImagePath, isObjEmpty, returnLabelWithCheck } from '../../utilsFunctions';
import methods from '../../api/methods';
import { WebsiteSettingsContext } from '../../contexts/WebsiteSettingsContext';
import { startLoading, stopLoading, replaceLoaders } from '../../services/loader';
import Image from '../../components/image/Image';

const AboutPage = () => {
  const { t } = useTranslation();
  const [aboutUsInfo, setAboutUsInfo] = useState({})
  const {settings, direction} = useContext(WebsiteSettingsContext);

  const payload = {
    website: settings.websiteId,
  }

  const aboutHeading = settings && settings.langText && settings.langText.WebAboutUs;

  useEffect(() => {
    let mounted = true;
    if (!isObjEmpty(settings)) {
      startLoading({loaders: ['about']});
      methods.getAboutData(payload)
      .then(res => {
        const data = res.data;
        mounted && setAboutUsInfo(data);
      })
      .finally(() => stopLoading({loaders: ['about']}))
    } 
    // setAboutUsInfo(aboutUsMock);
    return () => {
      replaceLoaders({loaders: []})
      mounted = false;
    }
  }, [settings])

  const infoSection = (
    <div>
      <span className="spesialText">{aboutUsInfo.subTitle}</span>
      <p className="regularText">{aboutUsInfo.aboutUsText}</p>
    </div>
  )

  const imageSection = (
    <div className={styles.imageWrapper}>
      <Image
        alt='about'
        // effect="opacity"
        className={styles.image}
        src={formatImagePath(aboutUsInfo.aboutUsImg, 'about')}
      />
    </div>
  )

  return (
    <div>
      <TwoSidedPage direction={direction} pageHeading={returnLabelWithCheck(aboutHeading, t('about'))} first={infoSection} second={imageSection} />
    </div>
  )
}

export default AboutPage
