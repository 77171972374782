import React, { useContext } from 'react'
import get from "lodash/get";
import { WebsiteSettingsContext } from "../../contexts/WebsiteSettingsContext";
import { $cart, changeQty, removeItem, closeCart } from "../../services/cart";
import { useStore } from "effector-react";
import CartItems from "../../presenters/cart-items/CartItems";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

const CartItemsController = ({ className }) => {
    const { settings, direction } = useContext(WebsiteSettingsContext)
    const cart = useStore($cart)
    const {t} = useTranslation();
    const labels = {
        WebPrice: get(settings, 'langText.WebPrice') || "?",
        WebCartRemove: get(settings, 'langText.WebCartRemove') || "?",
        currencySymbol: get(settings, 'curranySimbol') || "?",
        emptyCart: t('emptyCart'),
    }
    const {maxQtyForSalePerProduct} = settings;

    // TODO Maximum quantity: first call get product details service and then call getMaxAvailableUnits - ???
    const getMaxAvailableUnits = (availableQty, allowPresale) => {
        
        if (availableQty === 0 && allowPresale) {
            return maxQtyForSalePerProduct
        }
        return maxQtyForSalePerProduct < availableQty ? maxQtyForSalePerProduct : availableQty;
    }

    return (
        <CartItems
            className={className}
            items={cart.items}
            labels={labels}
            onChangeQty={changeQty}
            onRemoveItem={removeItem}
            onBackToShopping={closeCart}
            direction={direction}
            getMaxAvailableUnits={getMaxAvailableUnits}
        />
    )
}

CartItemsController.propTypes = {
    className: PropTypes.string,
}

CartItemsController.defaultProps = {
    className: '',
}

export default CartItemsController
