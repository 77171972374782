import React, { useState, useContext } from 'react'
import styles from './MemberForm.module.scss'
import { Formik } from 'formik';
import * as Yup from "yup";
import Input from '../../form-fields/input/Input';
import Select from '../../form-fields/select/Select';
import MemberLogo from '../../../images/Member.png'
import SideBySideInputs from '../../side-by-side-inputs/SideBySideInputs';
import SuccessMessage from '../../success-message/SuccessMessage';
import GiftIcon from '../../../images/clubSent.png'
import { WebsiteSettingsContext } from '../../../contexts/WebsiteSettingsContext';
import { isObjEmpty } from '../../../utilsFunctions';
import methods from '../../../api/methods';
import { useTranslation } from 'react-i18next';

const MemberForm = ({memberData}) => {
  const {settings, direction} = useContext(WebsiteSettingsContext);
  const {langText} = settings;

  const gendersList = [
    {
      label: langText && langText.WebMale,
      value: 'male',
    },
    {
      label: langText && langText.WebFemale,
      value: 'female',
    },
    {
      label: langText && langText.WebIdRather,
      value: 'none',
    },
  ]
  const [values] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: '',
    city: '',
    country: '',
    zip: '',
    gender: gendersList[0].value,
    birthDay: '',
    birthMonth: '',
    birthYear: '',
  })
  const [isLoading, setLoading] = useState(false);
  const [isFormSent, setIsFormSent] = useState(false);
  const [isFormFailed, setIsFormFailed] = useState(false);
  const {t} = useTranslation();

  const memberValidationSchema = Yup.object().shape({
    email: Yup.string().email(t('emailInvalid'))
      .max(255, t('tooLong'))
      .required(t('fieldIsRequired')),
    firstName: Yup.string()
      .max(255, t('tooLong'))
      .required(t('fieldIsRequired')),
    lastName: Yup.string()
      .max(255, t('tooLong'))
      .required(t('fieldIsRequired')),
    phoneNumber: Yup.string()
      .min(7, t('tooShort'))
      .max(255, t('tooLong'))
      .required(t('fieldIsRequired')),
    gender: Yup.string()
      .required(t('fieldIsRequired')),
    birthDay: Yup.number()
      .required(t('fieldIsRequired')),
    birthMonth: Yup.number()
      .required(t('fieldIsRequired')),
    birthYear: Yup.number()
      .required(t('fieldIsRequired')),
  })


  const onFormSubmit = (values) => {
    console.log('final form values: ', values)

    // validate email BE method isn't working now!!!

    // !isObjEmpty(settings) && methods.validateUniqueCustomerMail(values.email)
    // .then(res => {
      if (!isObjEmpty(settings)) {
        setLoading(true);
        methods.saveNewMember(values)
        .then(res => setIsFormSent(true))
        .catch(err => {
          console.log(err);
          setIsFormSent(false);
          setIsFormFailed(true);
        })
        .finally(() => setLoading(false))
      }
    // })
  }

  const backToForm = () => {
    setIsFormSent(false);
    setIsFormFailed(false);
  }

  if (isFormSent) {
    return (
      <SuccessMessage icon={GiftIcon} message={langText && langText.WebClubSigned} />
    )
  }

  if (isFormFailed) {
    return (
      <SuccessMessage isFormFailed={isFormFailed} backToForm={backToForm} />
    )
  }

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      onSubmit={(values) => onFormSubmit(values)}
      validationSchema={memberValidationSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => {
        const formFieldProps = {
          setFieldValue,
          onChange: handleChange,
          direction
        };

        return (
         <form className={styles.container}>
            <div className={styles.memberLogo}>
              <img alt="shop image" src={MemberLogo} />
            </div>
            <SideBySideInputs 
              field1={
                <Input
                  name='firstName'
                  placeholder={langText && langText.WebFirstName}
                  isTouched={touched && touched.firstName}
                  isError={errors && errors.firstName}
                  error={errors && errors.firstName}
                  // direction={direction}
                  isRequired
                  {...formFieldProps}
                />
              }
              field2={
                <Input
                  name='lastName'
                  placeholder={langText && langText.WebLastName}
                  isTouched={touched && touched.lastName}
                  isError={errors && errors.lastName}
                  error={errors && errors.lastName}
                  // direction={direction}
                  isRequired
                  {...formFieldProps}
                />
              }
            />
            <SideBySideInputs 
              field1={
                <Input
                  // isEmail
                  name='email'
                  placeholder={langText && langText.WebEmail}
                  isTouched={touched && touched.email}
                  isError={errors && errors.email}
                  error={errors && errors.email}
                  // direction={direction}
                  isRequired
                  {...formFieldProps}
                />
              }
              field2={
                <Input
                  // numberOnly
                  name='phoneNumber'
                  placeholder={langText && langText.WebPhone}
                  isTouched={touched && touched.phoneNumber}
                  isError={errors && errors.phoneNumber}
                  error={errors && errors.phoneNumber}
                  // direction={direction}
                  isRequired
                  {...formFieldProps}
                />
              }
            />
            <SideBySideInputs 
              field1={
                <Input
                  name='address'
                  placeholder={langText && langText.WebAddress}
                  isTouched={touched && touched.address}
                  isError={errors && errors.address}
                  error={errors && errors.address}
                  // direction={direction}
                  {...formFieldProps}
                />
              }
              field2={
                <Input
                  name='city'
                  placeholder={langText && langText.WebCity}
                  isTouched={touched && touched.city}
                  isError={errors && errors.city}
                  error={errors && errors.city}
                  // direction={direction}
                  {...formFieldProps}
                />
              }
            />
            <SideBySideInputs 
              field1={
                <Select
                  name='country'
                  value={values.country}
                  placeholder={langText && langText.WebLastName}
                  isTouched={touched && touched.country}
                  isError={errors && errors.country}
                  error={errors && errors.country}
                  notSelectedLabel={langText && langText.WebCountry}
                  // direction={direction}
                  {...formFieldProps}
                >
                  {memberData && memberData.countryArray && memberData.countryArray.map(c => {
                    return (
                      <option key={c.countryName} value={c.countryName}>{c.countryName}</option>
                    )
                  })}
                </Select>
              }
              field2={
                <Input
                  name='zip'
                  placeholder={langText && langText.WebZipPostal}
                  isTouched={touched && touched.zip}
                  isError={errors && errors.zip}
                  error={errors && errors.zip}
                  {...formFieldProps}
                />
              }
            />
            <SideBySideInputs
              noMobileWrap
              label={`${langText && langText.WebBirthday}*`}
              field1={
                <Select
                  name='birthDay'
                  value={values.birthDay}
                  isTouched={touched && touched.birthDay}
                  isError={errors && errors.birthDay}
                  error={errors && errors.birthDay}
                  notSelectedLabel="DD"
                  // direction={direction}
                  {...formFieldProps}
                >
                  {memberData && memberData.daysArray && memberData.daysArray.map(c => {
                    return (
                      <option key={c.dayName} value={c.dayName}>{c.dayName}</option>
                    )
                  })}
                </Select>
              }
              field2={
                <Select
                  name='birthMonth'
                  value={values.birthMonth}
                  isTouched={touched && touched.birthMonth}
                  isError={errors && errors.birthMonth}
                  error={errors && errors.birthMonth}
                  notSelectedLabel="MM"
                  // direction={direction}
                  {...formFieldProps}
                >
                  {memberData && memberData.monthsArray && memberData.monthsArray.map(c => {
                    return (
                      <option key={c.monthName} value={c.monthName}>{c.monthName}</option>
                    )
                  })}
                </Select>
              }
              field3={
                <Select
                  name='birthYear'
                  value={values.birthYear}
                  isTouched={touched && touched.birthYear}
                  isError={errors && errors.birthYear}
                  error={errors && errors.birthYear}
                  notSelectedLabel="YYYY"
                  // direction={direction}
                  {...formFieldProps}
                >
                  {memberData && memberData.yearsArray && memberData.yearsArray.map(c => {
                    return (
                      <option key={c.yearName} value={c.yearName}>{c.yearName}</option>
                    )
                  })}
                </Select>
              }
            />
            <Select
              name='gender'
              noEmptyOption
              value={values.gender}
              label={`${langText && langText.WebGender}*`}
              isTouched={touched && touched.gender}
              isError={errors && errors.gender}
              error={errors && errors.gender}
              // direction={direction}
              {...formFieldProps}
            >
              {gendersList.map(gender => {
                return (
                  <option key={gender.value} value={gender.value}>{gender.label}</option>
                )
              })}
            </Select>
            <div className={styles.requiredInfoContainer}>
            {langText && langText.WebMandatory} - <b>*</b>
            </div>
            <div className={styles.buttonContainer}>
              <button className={styles.buttonSubmit} onClick={handleSubmit}>{langText && langText.WebSignUp}</button>
            </div>
            <p className={styles.confirmText}>
              {langText && langText.WebByClicking1} 
              <b>{langText && langText.WebByClicking2}</b>
              {langText && langText.WebByClicking3}
              <span> <a rel='noopener noreferrer' target="_blank" href="/terms-of-use" className={styles.link}>{langText && langText.WebByClicking4}</a> </span>
              {langText && langText.WebByClicking5}
              <a rel='noopener noreferrer' target="_blank" href="/privacy-policy" className={styles.link}>{langText && langText.WebByClicking6}</a>.
            </p>
          </form>
        )
      }}
      </Formik>
      )
}

export default MemberForm
