import React from 'react'
import styles from './ProductsList.module.scss'
import ProductCard from '../product-card/ProductCard'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'


const ProductsList = ({ 
  products,
  title,
  direction,
  withBreadCrumbs
}) => {
  const {t} = useTranslation();
  const listTitle = title ? title : t('selectedItems');
  return (
    <div className={clsx(styles.wrapper, {[styles.withBreadCrumbs]: withBreadCrumbs})}>
      <div className={styles.title}>{listTitle}</div>
      <div className={clsx(styles[direction], styles.container)}>
        {products && products.length > 0 ? 
          products.map(product => {
            return (
              <div key={product.pdImg} className={styles.productWrapper}>
                <ProductCard product={product} direction={direction} />
              </div>
            )
          }) : null
        }
      </div>
    </div>
  )
}

export default ProductsList
