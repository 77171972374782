import React from 'react'
import styles from './TwoSidedPage.module.scss'
import clsx from 'clsx'

const TwoSidedPage = ({pageHeading, first, second, direction}) => {
  return (
    <div className={clsx(styles[direction], styles.wrapper)}>
      <div className={styles.underTitleSection}>
        <div className={styles.bigTitle}>{pageHeading}</div>
        <div>{first}</div>
      </div>
      <div className={styles.secondSection}>{second}</div>
    </div>
  )
}

export default TwoSidedPage
