import React, { useState, useContext } from 'react'
import styles from './ContactForm.module.scss'
import Input from '../../form-fields/input/Input'
import Select from '../../form-fields/select/Select'
import TextArea from '../../form-fields/text-area/TextArea'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import SideBySideInputs from '../../side-by-side-inputs/SideBySideInputs'
import { WebsiteSettingsContext } from '../../../contexts/WebsiteSettingsContext'
import SuccessMessage from '../../success-message/SuccessMessage'
import MailIcon from '../../../images/emailSent.png'
import { returnLabelWithCheck, isObjEmpty } from '../../../utilsFunctions'
import methods from '../../../api/methods'
import NanoLoader from '../../NanoLoader'
import * as Yup from "yup";

const ContactForm = ({contactUsInfo}) => {
  const [values] = useState({
    name: '',
    email: '',
    subject: '',
    body: '',
  })
  const [isLoading, setLoading] = useState(false);
  const [isFormSent, setIsFormSent] = useState(false);
  const [isFormFailed, setIsFormFailed] = useState(false);
  const [mounted, setMounted] = useState(true);
  const {t} = useTranslation()

  const contactValidationSchema = Yup.object().shape({
    email: Yup.string().email(t('emailInvalid')) 
      .max(255, t('tooLong'))
      .required(t('fieldIsRequired')),
    name: Yup.string()
      .max(255, t('tooLong'))
      .required(t('fieldIsRequired')),
    subject: Yup.string()
      .required(t('fieldIsRequired')),
    body: Yup.string()
      .required(t('fieldIsRequired')),
  })
  
  const {settings, direction} = useContext(WebsiteSettingsContext);
  const {langText} = settings;

  const nameLabel = langText && langText.WebName;
  const emailLabel = langText && langText.WebEmail;
  const subjectLabel = langText && langText.WebEmailSubject;
  const messageLabel = langText && langText.WebMessage;
  const sendLabel = langText && langText.WebSend;

  const onFormSubmit = (values) => {
    const finalValues = {
      ...values,
      subject: langText && langText.WebEmailSubjectPrefix + ' ' + values.subject
    };

    console.log('Is ContactForm mounted: ', mounted)
    if (!isObjEmpty(settings)) {
      mounted && setLoading(true);
      methods.sendContactFormByMail({
      website: settings.websiteId,
      lang: settings.lang,
      ...finalValues,
    })
      .then(res => {
        console.log('submit res: ', res)
        mounted && setIsFormSent(true)
      })
      .catch(err => {
        console.log(err);
        mounted && setIsFormSent(false);
        mounted && setIsFormFailed(true);
      })
      .finally(() => mounted && setLoading(false))
    }
  }

  const backToForm = () => {
    setIsFormSent(false);
    setIsFormFailed(false);
  }

  if (isFormSent) {
    return (
      <SuccessMessage iconWidth="66px" icon={MailIcon} message={langText && langText.WebMessageSent} isFormFailed={isFormFailed} />
    )
  }

  if (isFormFailed) {
    return (
      <SuccessMessage isFormFailed={isFormFailed} backToForm={backToForm} />
    )
  }

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      onSubmit={(values) => onFormSubmit(values)}
      validationSchema={contactValidationSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => {
        const formFieldProps = {
          setFieldValue,
          onChange: handleChange,
        };

        return (
          <NanoLoader isLoading={isLoading}>
          <form className={styles.container}>
            <SideBySideInputs
                field1={
                  <Input
                    name='name'
                    placeholder={returnLabelWithCheck(nameLabel, t('name'))}
                    isTouched={touched && touched.name}
                    isError={errors && errors.name}
                    error={errors && errors.name}
                    direction={direction}
                    {...formFieldProps}
                  />
                }
                field2={
                  <Input
                    // isEmail
                    name='email'
                    placeholder={returnLabelWithCheck(emailLabel, t('email'))}
                    isTouched={touched && touched.email}
                    isError={errors && errors.email}
                    error={errors && errors.email}
                    direction={direction}
                    {...formFieldProps}
                  />
                }
              />
              <Select
                name='subject'
                value={values.subject}
                notSelectedLabel={returnLabelWithCheck(subjectLabel, t('subject'))}
                isTouched={touched && touched.subject}
                isError={errors && errors.subject}
                error={errors && errors.subject}
                direction={direction}
                {...formFieldProps}
              >
                {contactUsInfo && contactUsInfo.subjectsList && contactUsInfo.subjectsList.map(c => {
                  return (
                    <option key={c.subjectName} value={c.subjectName}>{c.subjectName}</option>
                  )
                })}
              </Select>
              <TextArea
                name='body'
                placeholder={returnLabelWithCheck(messageLabel, t('message'))}
                isTouched={touched && touched.body}
                isError={errors && errors.body}
                error={errors && errors.body}
                direction={direction}
                {...formFieldProps}
              />
              <div className={styles.buttonContainer}>
                <button className={styles.buttonSubmit} onClick={handleSubmit}>{returnLabelWithCheck(sendLabel, t('send'))}</button>
              </div>
            </form>
          </NanoLoader>
        )
      }}
      </Formik>
      )
}

export default ContactForm
