import api from './api'
import qs from "query-string"
import { formatObjectToFormData } from '../utilsFunctions';

 const methods = {
  getWebsiteSettings() {
    return api.get(`Controllers/aj_getWebsiteSettings.aspx`);
  },
  getCategoriesAndProductsList(payload) {
    return api.post('Controllers/catalog/getData/getCategoriesAndProductsList.aspx', qs.stringify(payload));
  },
  getAboutData(payload) {
    return api.post('Controllers/aboutUs/GetData/aj_getAboutData.aspx', qs.stringify(payload));
  },
  getContactUsData(payload) {
    return api.post('Controllers/contactUs/getData/aj_getContactUsData.aspx', qs.stringify(payload));
  },
  getMemberClubData(payload) {
    return api.post('Controllers/memberClub/getData/aj_getMemberClubData.aspx', qs.stringify(payload));
  },
  getPrivacyPolicyData(payload) {
    return api.post('Controllers/memberClub/getData/aj_getPrivacyPolicyData.aspx', qs.stringify(payload));
  },
  getTermsOfUseData(payload) {
    return api.post('Controllers/memberClub/getData/aj_getTermsOfUseData.aspx', qs.stringify(payload));
  },
  getProductDetails(payload) {
    return api.post('Controllers/catalog/getData/getProductDetails.aspx', qs.stringify(payload));
  },
  saveNewMember(payload) {
    return api.post('Controllers/memberClub/saveData/aj_saveNewMember.aspx', qs.stringify(payload));
  },
  sendContactFormByMail(payload) {
    return api.post('Controllers/contactUs/saveData/aj_sendContactFormByMail.aspx', qs.stringify(payload));
  },
  getShipmentOptions(payload) {
    return api.post('Controllers/aj_getWebsiteDeliveryOptions.aspx', qs.stringify(payload));
  },
  createOrder(payload) {
    return api.post('Controllers/aj_createOrder.aspx', formatObjectToFormData(payload));
  },
  validateUniqueCustomerMail(memberClubEmail) {
    // this endpoint below returns 404 (check what is the issue)
    return api.get(`aj_validateUniqueCustomerMail.aspx?memberClubEmail=${memberClubEmail}`);
  },
  getSessionId() {
    return api.get(`Controllers/getSessionId.asp`);
  },
};

export default methods
