import { createEvent, createStore } from 'effector'

const startLoading = createEvent()
const stopLoading = createEvent()
const replaceLoaders = createEvent()
const $loaders = createStore([])
    .on(startLoading, (s, { loaders }) => {
      console.log()
      return [...s, ...loaders]
    })
    .on(stopLoading, (s, { loaders }) => s.filter(existing => !loaders.includes(existing)))
    .on(replaceLoaders, (s, { loaders }) => loaders)

export {
  $loaders,
  startLoading,
  stopLoading,
  replaceLoaders
}
