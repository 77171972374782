import React from 'react'
import styles from './FormGroup.module.scss'
import PropTypes from "prop-types";
import clsx from "clsx";

const FormGroup = ({ children, className }) => {
    return (
        <div className={clsx(styles.formGroup, className)}>
            <>{children}
            </>
        </div>
    )
}

FormGroup.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any.isRequired,
}

FormGroup.defaultProps = {
    className: '',
}

export default FormGroup
