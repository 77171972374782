import React from 'react'
import styles from './CategoryCard.module.scss'
import { Link } from 'react-router-dom'
import { formatImagePath } from '../../utilsFunctions'
import clsx from 'clsx'
import Image from '../image/Image'
import { parentPath } from '../../App'

const CategoryCard = ({ category, direction }) => {
  return (
    <Link to={`${parentPath}/catalog/${category.catId}`}>
      <div className={clsx(styles[direction], styles.category)}>
        <Image src={formatImagePath(category.catImg, 'category')} alt="category" className={styles.categoryImg} />
        <div className={styles.categoryName}>{category.catName}</div>
      </div>
    </Link>
  )
}

export default CategoryCard
