import React from 'react'
import styles from './CategoryList.module.scss'
import CategoryCard from '../category-card/CategoryCard'
import clsx from 'clsx'

const CategoryList = ({ categories, title, direction, withBreadCrumbs }) => {
  return (
    <div>
      {title && <div className={styles.title}>{title}</div>}
      <div className={clsx(styles[direction], styles.container, {[styles.withBreadCrumbs]: withBreadCrumbs})}>
        {categories && categories.length > 0 ? 
          categories.map(cat => {
            return (
              <div key={cat.catId} className={styles.categoryWrapper}>
                <CategoryCard category={cat} direction={direction} />
              </div>
            )
          }) : null
        }
      </div>
    </div>
  )
}

export default CategoryList
