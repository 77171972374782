import React from 'react'
import styles from './CheckoutFormFields.module.scss'
import PropTypes from "prop-types";
import FormGroup from "../form-group/FormGroup";
import Input from "../../components/form-fields/input/Input";
import RadioInput from "../RadioInput";
import clsx from "clsx";
import Error from "../error/Error";
import SubmitButton from "../submit-button/SubmitButton";
import Select from "../../components/form-fields/select/Select";

const CheckoutFormFields = ({ values, shipmentOptions, onChange, className, direction, errors, touched, labels, hideAddress, years, months, isSubmitButtonDisabled }) => {
    const onChangeField = onChange

    return (
        <div className={clsx(className, styles[direction])}>
            <div className={styles.fieldset}>
                <div className={styles.title}>{labels.contactInformation}</div>
                <div className={styles.row}>
                    <div className={styles.col}>
                        <Input
                            direction={direction}
                            theme="checkout"
                            name="firstName"
                            value={values.firstName}
                            onChange={onChangeField}
                            placeholder={labels.firstName}
                            error={errors.firstName}
                            isTouched={touched.firstName}
                        />
                    </div>
                    <div className={styles.col}>
                        <Input
                            direction={direction}
                            theme="checkout"
                            name="lastName"
                            value={values.lastName}
                            onChange={onChangeField}
                            placeholder={labels.lastName}
                            error={errors.lastName}
                            isTouched={touched.lastName}
                        />
                    </div>
                </div>
                <Input
                    direction={direction}
                    theme="checkout"
                    name="phoneNo1"
                    value={values.phoneNo1}
                    onChange={onChangeField}
                    placeholder={labels.phoneNo1}
                    error={errors.phoneNo1}
                    isTouched={touched.phoneNo1}
                    numberOnly
                />
                <Input
                    direction={direction}
                    theme="checkout"
                    name="phoneNo2"
                    value={values.phoneNo2}
                    onChange={onChangeField}
                    placeholder={labels.phoneNo2}
                    error={errors.phoneNo2}
                    isTouched={touched.phoneNo2}
                    numberOnly
                />
                <Input
                    direction={direction}
                    theme="checkout"
                    name="email"
                    value={values.email}
                    onChange={onChangeField}
                    placeholder={labels.email}
                    error={errors.email}
                    isTouched={touched.email}
                />
            </div>
            <div className={styles.fieldset}>
                <div className={styles.title}>{labels.shippingMethod}</div>
                <FormGroup>
                    {
                        shipmentOptions.map((shipmentOption) => {
                            return (
                            <div className={styles.radio} key={shipmentOption.value}>
                                <RadioInput
                                    className={styles.radioInput}
                                    inputId={`shipment_${shipmentOption.value}`}
                                    name="shippingMethodId"
                                    value={shipmentOption.value}
                                    defaultChecked={values.shippingMethodId === shipmentOption.value}
                                    onChange={onChangeField}
                                />
                                <label htmlFor={`shipment_${shipmentOption.value}`} className={styles.radioLabel}>{shipmentOption.name}</label>
                            </div>
                        )})
                    }
                    {
                        errors.shippingMethodId && touched.shippingMethodId &&
                        <Error direction={direction} error={errors.shippingMethodId} />
                    }
                </FormGroup>
            </div>
            {
                !hideAddress &&
                <div className={styles.fieldset}>
                    <div className={styles.title}>{labels.shippingAddress}</div>
                    <Input
                        direction={direction}
                        theme="checkout"
                        name="shippingAddress1"
                        value={values.shippingAddress1}
                        onChange={onChangeField}
                        placeholder={labels.shippingAddress1}
                        error={errors.shippingAddress1}
                        isTouched={touched.shippingAddress1}
                    />
                    <Input
                        direction={direction}
                        theme="checkout"
                        name="shippingAddress2"
                        value={values.shippingAddress2}
                        onChange={onChangeField}
                        placeholder={labels.shippingAddress2}
                        error={errors.shippingAddress2}
                        isTouched={touched.shippingAddress2}
                    />
                    <Input
                        direction={direction}
                        theme="checkout"
                        name="city"
                        value={values.city}
                        onChange={onChangeField}
                        placeholder={labels.city}
                        error={errors.city}
                        isTouched={touched.city}
                    />
                    <div className={styles.row}>
                        <div className={styles.col}>
                            <Input
                                direction={direction}
                                theme="checkout"
                                name="state"
                                value={values.state}
                                onChange={onChangeField}
                                placeholder={labels.state}
                                error={errors.state}
                                isTouched={touched.state}
                            />
                        </div>
                        <div className={styles.col}>
                            <Input
                                direction={direction}
                                theme="checkout"
                                name="zipcode"
                                value={values.zipcode}
                                onChange={onChangeField}
                                placeholder={labels.zipcode}
                                error={errors.zipcode}
                                isTouched={touched.zipcode}
                            />
                        </div>
                    </div>
                </div>
            }
            <div className={styles.fieldset}>
                <div className={styles.title}>{labels.payment}</div>
                <Input
                    direction={direction}
                    theme="checkout"
                    name="CCNumber"
                    value={values.CCNumber}
                    onChange={onChangeField}
                    placeholder={labels.CCNumber}
                    error={errors.CCNumber}
                    isTouched={touched.CCNumber}
                    numberOnly
                />
                <div className={styles.row}>
                    <div className={styles.ccExpiration}>
                        <div className={styles.row}>
                            <div className={styles.col}>
                                <Select
                                    direction={direction}
                                    theme="checkout"
                                    name="CCMonth"
                                    value={values.CCMonth}
                                    isTouched={touched.CCMonth}
                                    isError={errors.CCMonth}
                                    error={errors.CCMonth}
                                    notSelectedLabel="MM"
                                    onChange={onChangeField}
                                >
                                    {months.map(name => <option key={name} value={name}>{name}</option>)}
                                </Select>
                            </div>
                            <div className={styles.col}>
                                <Select
                                    direction={direction}
                                    theme="checkout"
                                    name="CCYear"
                                    value={values.CCYear}
                                    isTouched={touched.CCYear}
                                    isError={errors.CCYear}
                                    error={errors.CCYear}
                                    notSelectedLabel="YYYY"
                                    onChange={onChangeField}
                                >
                                    {years.map(name => <option key={name} value={name}>{name}</option>)}
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className={styles.ccCvv}>
                        <Input
                            direction={direction}
                            theme="checkout"
                            name="CVV"
                            value={values.CVV}
                            onChange={onChangeField}
                            placeholder={labels.CVV}
                            error={errors.CVV}
                            isTouched={touched.CVV}
                            numberOnly
                        />
                    </div>
                </div>
                <Input
                    direction={direction}
                    theme="checkout"
                    name="cardholderID"
                    value={values.cardholderID}
                    onChange={onChangeField}
                    placeholder={labels.cardholderID}
                    error={errors.cardholderID}
                    isTouched={touched.cardholderID}
                />
            </div>

            <div className={styles.submitBlock}>
                <SubmitButton type="submit" direction={direction} disabled={isSubmitButtonDisabled}>{labels.confirm}</SubmitButton>
            </div>
        </div>
    )
}

CheckoutFormFields.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    values: PropTypes.object,
    direction: PropTypes.oneOf(['ltr', 'rtl']).isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    labels: PropTypes.object.isRequired,
    shipmentOptions: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        }),
    ).isRequired,
    hideAddress: PropTypes.bool,
}

CheckoutFormFields.defaultProps = {
    className: '',
    values: {},
    hideAddress: false,
}

export default CheckoutFormFields
