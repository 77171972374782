import React, { useContext } from 'react'
import styles from './Footer.module.scss';
import { useTranslation } from 'react-i18next';
import footerLogo from './logoFooter.png';
import { returnLabelWithCheck } from '../../utilsFunctions';
import { WebsiteSettingsContext } from '../../contexts/WebsiteSettingsContext';
import { parentPath } from '../../App';

const Footer = () => {
  const {t} = useTranslation();
  const {settings} = useContext(WebsiteSettingsContext);
  const {showTermsOfUse, showPrivacyPolicy} = settings;
  return (
    <footer className={styles.container}>
      <a rel="noopener noreferrer" href="http://www.getpose.com" target="_blank" className={styles.footerLogoContainer}>
          <img alt="POSE" src={footerLogo} className={styles.footerLogo}></img>
          <p className={styles.footerText}>Powered by Pose</p>
      </a>


      <p className={styles.footerLinks}>
          {showTermsOfUse ? 
          <span>
              <a rel="noopener noreferrer" href={`${parentPath}/terms-of-use`} target="_blank">{settings.langText && returnLabelWithCheck(settings.langText.WebByClicking4, t('termsOfUse'))}</a>
          </span> : null
          }
          {showTermsOfUse && showPrivacyPolicy ? <span> &nbsp;&nbsp;|&nbsp;&nbsp; </span> : null}
          {showPrivacyPolicy ? 
          <span>
              <a rel="noopener noreferrer" href={`${parentPath}/privacy-policy`} target="_blank">{settings.langText && returnLabelWithCheck(settings.langText.WebByClicking6, t('privacyPolicy'))}</a>
          </span> : null
          }
      </p>
    </footer>
  )
}

export default Footer
