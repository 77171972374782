import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import WebsiteSettingsContextProvider from './contexts/WebsiteSettingsContext';
import CartModal from "./components/cart-modal/CartModal";
import NanoLoader from './components/NanoLoader';
import { useStore } from 'effector-react';
import { $loaders } from './services/loader';
import Routes from './components/Routes';

export const parentPath = '/shop';

const App = () => {
  const loaders = useStore($loaders);
  return (
    <Router>
      <WebsiteSettingsContextProvider>
        <div>
          <div className="app__wrapper">
            <Header />
            <NanoLoader isLoading={loaders.length > 0}>
              <Routes />
            </NanoLoader>
          </div>
          <Footer />
        </div>
        <CartModal />
      </WebsiteSettingsContextProvider>
    </Router>
  );
}

export default App;
