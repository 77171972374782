import LsStorage from "../libs/LsStorage";
import { createEvent, createStore } from 'effector'
import cloneDeep from 'lodash/cloneDeep'
import globalScroll from "./globalScroll";

const storage = new LsStorage('cart.v1')
const DEFAULT_STATE = {
    items: [],
}
const addItem = createEvent()
const changeQty = createEvent()
const removeItem = createEvent()
const removeItemsByProductOrderIds = createEvent()
const $cart = createStore(storage.load('$cart') || DEFAULT_STATE)
    .on(addItem, (s, { qty, product }) => {
        const productOrderId = Math.random().toString(36).substr(2, 9);
        const items = [...s.items, { qty, product, productOrderId }]
        return { ...s, items }
    })
    .on(changeQty, (s, { productOrderId, qty }) => {
        const items = cloneDeep(s.items);
        const foundIndex = items.findIndex(x => x.productOrderId == productOrderId);
        items[foundIndex].qty = isNaN(qty) ? 0 : qty
        return { ...s, items }
    })
    .on(removeItem, (s, { index }) => {
        const items = cloneDeep(s.items)
        items.splice(index, 1)
        return { ...s, items }
    })
    .on(removeItemsByProductOrderIds, (s, productOrderIdsArr) => {
        let items = cloneDeep(s.items).filter(item => !productOrderIdsArr.includes(item.productOrderId))
        return { ...s, items }
    })

$cart.watch((s) => storage.save('$cart', s))

const openCart = createEvent()
const closeCart = createEvent()
const $cartIsModalOpen = createStore(false)
    .on(openCart, () => true)
    .on(closeCart, () => false)

$cartIsModalOpen.watch((isOpen) => {
    if (isOpen) {
        globalScroll.lock()
    } else {
        globalScroll.unLock()
    }
})

const getTotalItemsCount = (items) => items.reduce((acc, { qty }) => qty + acc, 0)
const getTotalItemsAmount = (items) => items.reduce((acc, { qty, product }) => ( qty * product.onlinePrice) + acc, 0)

export {
    $cart,
    addItem,
    changeQty,
    removeItem,
    $cartIsModalOpen,
    openCart,
    closeCart,
    removeItemsByProductOrderIds,
    getTotalItemsCount,
    getTotalItemsAmount,
}
