import React, { useState, useEffect, useContext } from 'react'
// import styles from './ContactPage.module.scss'
import TwoSidedPage from '../../components/pages-layouts/two-sided-page/TwoSidedPage'
import { useTranslation } from 'react-i18next';
import ContactForm from '../../components/forms/contact-form/ContactForm'
import InfoWithLabel from '../../components/info-with-label/InfoWithLabel';
import methods from '../../api/methods';
import { WebsiteSettingsContext } from '../../contexts/WebsiteSettingsContext';
import { isObjEmpty } from '../../utilsFunctions';
import { startLoading, stopLoading, replaceLoaders } from '../../services/loader';

const ContactPage = () => {
  const { t } = useTranslation();
  const [contactUsInfo, setContactUsInfo] = useState({})
  const {settings, direction} = useContext(WebsiteSettingsContext);
  const {langText} = settings;

  const contactLabel = langText ? langText.WebContactUs : t('contactUs');
  const addressLabel = langText ? langText.WebAddress : t('address');
  const phoneLabel = langText ? langText.WebPhone : t('phoneNumber');

  const payload = {
    website: settings.websiteId,
    lang: settings.lang || 'en',
  }
  useEffect(() => {
    let mounted = true;
    if (!isObjEmpty(settings)) {
      startLoading({loaders: ['contact']});
      methods.getContactUsData(payload)
      .then(res => mounted && setContactUsInfo(res.data))
      .finally(() => stopLoading({loaders: ['contact']}))
    }
    // setContactUsInfo(contactUsMock)
    return () => {
      replaceLoaders({loaders: []})
      mounted = false;
    }
  }, [settings])

  const infoSection = (
    <div>
      {contactUsInfo.showAddress && <InfoWithLabel label={addressLabel} info={contactUsInfo.storeAddress} />}
      <InfoWithLabel label={phoneLabel} info={contactUsInfo.storePhoneNum} />
    </div>
  )

  const formSection = (
    <ContactForm contactUsInfo={contactUsInfo} />
  )

  return (
    <div>
      <TwoSidedPage direction={direction} pageHeading={contactLabel} first={infoSection} second={formSection} />
    </div>
  )
}

export default ContactPage
