import axios from 'axios';

// const apiUrl = 'http://localhost:8010/proxy/Ecommerce/';
// const apiUrl = 'https://35.153.31.88/Ecommerce/'; // this is real test IP
const apiUrl = process.env.REACT_APP_API_URL || '/Ecommerce/';  // this is real production domain
// const apiUrl = 'https://cors-anywhere.herokuapp.com/https://bigmama.gotpose.com/Ecommerce/';
// const apiUrl = 'http://0.0.0.0:8081/Ecommerce/'; // this is a proxy IP
const api = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  }
})

export default api
