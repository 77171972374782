import React, { useState, useEffect, useContext } from 'react'
import styles from './CatalogMainPage.module.scss'
import CategoryList from '../../components/category-list/CategoryList';
import ProductsList from '../../components/products-list/ProductsList';
import methods from '../../api/methods';
import { WebsiteSettingsContext } from '../../contexts/WebsiteSettingsContext';
import { isObjEmpty } from '../../utilsFunctions';
import { stopLoading, startLoading, replaceLoaders } from '../../services/loader';

const CatalogMainPage = () => {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const {settings, direction} = useContext(WebsiteSettingsContext);

  const payload = {
    catId: 0,
    website: settings.websiteId,
    isFeatured: 1,
  }

  useEffect(() => {
    let mounted = true;
    if (!isObjEmpty(settings)) {
      startLoading({loaders: ['mainCatalog']});
       methods.getCategoriesAndProductsList(payload)
      .then(res => {
        const data = res.data;
        if (mounted) {
          setCategories(data.categories)
          setProducts(data.products)
        }
      })
      .finally(() => stopLoading({loaders: ['mainCatalog']}))
    }
    return () => {
      replaceLoaders({loaders: []})
      mounted = false;
    }
  }, [settings])

  return (
    <div className={styles.container}>
      <CategoryList direction={direction} categories={categories} />
      {settings.showFeaturedItems && <ProductsList direction={direction} products={products} title={`- ${settings.featuredTitle} -`}/>}
    </div>
  )
}

export default CatalogMainPage
