import React, { useEffect, useContext, useState } from 'react'
import styles from './OneProductPage.module.scss'
import { useParams } from 'react-router';
import methods from '../../api/methods';
import { isObjEmpty, formatImagePath } from '../../utilsFunctions';
import { WebsiteSettingsContext } from '../../contexts/WebsiteSettingsContext';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";
import { openCart, addItem, $cart, changeQty } from "../../services/cart";
import QtySelect from "../../presenters/qty-select/QtySelect";
import { startLoading, stopLoading, replaceLoaders } from '../../services/loader';
import Image from '../../components/image/Image';
import BreadCrumbs from '../../components/breadcrumbs/BreadCrumbs';
import { useStore } from 'effector-react';
import { $breadCrumbs, clearBreadCrumbs } from '../../services/breadCrumbs';
import LabelNew from '../../components/label-new/LabelNew';
import { formatNumber, returnLabelWithCheck } from "../../utilsFunctions";

const NextArrow = ({ className, onClick }) => {
  return (
    <div
      className={clsx(className, styles.sliderArrow)}
      style={{ right: 20, zIndex: 1, backgroundPosition: '-51px 0' }}  
      onClick={onClick}
      fill="white"
    />
  );
}

const PrevArrow = ({ className, onClick }) => {
  return (
    <div
      className={clsx(className, styles.sliderArrow)}
      style={{ left: 20, zIndex: 1, backgroundPosition: '0 0' }}
      onClick={onClick}
      fill="white"
    />
  );
}

const OneProductPage = () => {
  let { t } = useTranslation();
  let { sku } = useParams();
  const [product, setProduct] = useState({});
  const [counter, setCounter] = useState(1);
  const {settings, direction} = useContext(WebsiteSettingsContext);
  const breadCrumbs = useStore($breadCrumbs)
  const finalPrice = product.isOnSale ? product.onlineSalePrice : product.onlinePrice;
  const {maxQtyForSalePerProduct} = settings;
  const {availableQty, allowPresale} = product;
  const cart = useStore($cart);

  const getMaxAvailableUnits = () => {
    if (availableQty === 0 && allowPresale) {
      return maxQtyForSalePerProduct
    }
    return maxQtyForSalePerProduct < availableQty ? maxQtyForSalePerProduct : availableQty;
  }

  const isCartIncludesMaxQtyOfProduct = cart.items.some(item => item.product.pd_id === product.pd_id && item.qty >= getMaxAvailableUnits());
  const isCartIncludesSomeQtyOfProduct = cart.items.some(item => item.product.pd_id === product.pd_id && item.qty < getMaxAvailableUnits());

  const productInCart = cart.items.filter(item => item.product.pd_id === product.pd_id)[0];

  const qtySpaceLeft = productInCart ? getMaxAvailableUnits() - productInCart.qty : getMaxAvailableUnits();
  const finalQtyToAdd = counter > qtySpaceLeft ? qtySpaceLeft : counter;
  const isAddToCartButtonDisabled = getMaxAvailableUnits() < 1 || counter < 1 || finalQtyToAdd < 1 || isCartIncludesMaxQtyOfProduct;

  const payload = {
    website: settings.websiteId,
    sku,
  }

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>,
  };

  useEffect(() => {
    let mounted = true;
    if (!isObjEmpty(settings)) {
      startLoading({loaders: ['productDetails']});
      methods.getProductDetails(payload)
      .then(res => {
        const data = res.data;
        mounted && setProduct(data);
      })
      .finally(() => {
        stopLoading({loaders: ['productDetails']})
      })
    }

    return () => {
      replaceLoaders({loaders: []})
      mounted = false;
      clearBreadCrumbs();
    }
  }, [settings])

  const renderImage = () => {
      return (
          product && product.pdImageArray && product.pdImageArray.length > 0 ?
          (
            <Slider {...sliderSettings}>
              {product.pdImageArray.map(img => {
                return (
                  <Image
                    key={img.index}
                    alt='product'
                    className={styles.productImg}
                    src={formatImagePath(img.imageUrl, 'product')}
                  />
                )
              }) 
              }
            </Slider>
          ) : (
            <Image
              alt='product'
              className={styles.productImg} 
              src={formatImagePath(product.pdImg, 'product')}
            />
          )
      )
  }

  const incrementCounter = () => {
    getMaxAvailableUnits() < counter + 1 ? setCounter(getMaxAvailableUnits()): setCounter(counter + 1) 
  }

  const decrementCounter = () => {
    setCounter(counter - 1)
  }

  const onSetCounter = (value) => {
    return value > qtySpaceLeft ? setCounter(qtySpaceLeft) : setCounter(isNaN(value) ? counter : value)
  }

  const onAddToCart = () => {
    if (counter > getMaxAvailableUnits() || isCartIncludesMaxQtyOfProduct) {
      return;
    } else if (isCartIncludesSomeQtyOfProduct) {
      if (finalQtyToAdd < 1) {
        return;
      } else {
        changeQty({ productOrderId: productInCart.productOrderId, qty: productInCart.qty + finalQtyToAdd })
        openCart()
        setCounter(1)
      }
    } else {
      addItem({ qty: counter, product })
      openCart()
      setCounter(1)
    }
  }

  const labels = {
    addToCart: settings && settings.langText && settings.langText.WebAddToCart,
    sku: settings && settings.langText && settings.langText.WebIDNumber,
    limitMessage: settings && settings.langText && settings.langText.WebLimitQuantity + ' ' + maxQtyForSalePerProduct + ' ' + settings.langText.WebUnits,
  }

  return (
    <div className={clsx(styles[direction], styles.container)}>
      <BreadCrumbs breadCrumbs={breadCrumbs} />
      <div className={styles.title}>- {product.pdName} -</div>
      <div className={styles.content}>
        <div className={styles.imageColumn}>
          <div className={styles.imageContainer}>
            {renderImage()}
          </div>
        </div>
        <div className={styles.infoColumn}>
          <div className={styles.infoContainer}>
            <div className={styles.infoTop}>
              <div className={styles.priceContainer}>
                {
                  product && finalPrice
                      ? <div className={styles.price}>
                        {settings.curranySimbol}{formatNumber(finalPrice, { addDecimals: true })}
                      </div>
                      : null
                }
                {
                  product && getMaxAvailableUnits() < 1
                      ? <div className={styles.soldOut}>
                        {settings.langText && settings.langText.WebSoldout ? settings.langText.WebSoldout : t('soldOut')}
                      </div>
                      : null
                }
              </div>
              
              <div className={styles.addToCartContainer}>
                <QtySelect
                  counter={counter}
                  onIncrement={incrementCounter}
                  onDecrement={decrementCounter}
                  onChange={onSetCounter}
                  isIncrementDisabled={qtySpaceLeft < 1 || qtySpaceLeft === counter || counter === getMaxAvailableUnits()}
                  disabled={getMaxAvailableUnits() < 1} 
                />
                <button disabled={isAddToCartButtonDisabled} className={clsx('blackButton', styles.addButton)} onClick={onAddToCart}>{labels.addToCart}</button>
              </div>
              {productInCart && <div className={styles.limitMessage}>*{labels.limitMessage}</div>}
            </div>
            <div className={styles.infoBottom}>
              {product.isNew ?<div className={styles.newLabelContainer}><LabelNew /></div>: null}
              <span className={clsx('regularText')}>{returnLabelWithCheck(labels.sku, t('productCode'))}: {product.sku}</span>
              {product.pdOnlineDesc && <p className={clsx('regularText', styles.description)}>{product.pdOnlineDesc}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OneProductPage
