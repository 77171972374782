import React from 'react'
import styles from './QtySelect.module.scss'
import PropTypes from "prop-types";
import clsx from "clsx";

const QtySelect = ({ counter, onIncrement, onDecrement, onChange, theme, className, disabled, isIncrementDisabled }) => {
    return (
        <div className={clsx(styles.counter, styles[theme], className)}>
            <button  onClick={onDecrement} disabled={counter === 0 || disabled}> - </button>
            <input value={counter} disabled={disabled} onChange={(event => onChange(parseInt(event.target.value, 10)))} />
            <button className={'blackButton'} disabled={disabled || isIncrementDisabled} onClick={onIncrement}> + </button>
        </div>
    )
}

QtySelect.propTypes = {
    className: PropTypes.string,
    theme: PropTypes.oneOf(['', 'sm']),
    counter: PropTypes.number.isRequired,
    onIncrement: PropTypes.func.isRequired,
    onDecrement: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
}

QtySelect.defaultProps = {
    className: '',
    theme: '',
}

export default QtySelect
