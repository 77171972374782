import React from 'react'
import { useMediaQuery } from 'react-responsive';
import styles from './SideBySideInputs.module.scss'
import clsx from 'clsx';

const SideBySideInputs = ({field1, field2, field3, label, noMobileWrap}) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div className={styles.wrapper}>
      {label && <label className={styles.label}>{label}</label>}
      <div className={clsx({[styles.twoFieldsContainer]: !isMobile, [styles.noMobileWrap]: noMobileWrap, [styles.withLabel]: label})}>
        {field1}
        {!isMobile || noMobileWrap ? <div className={styles.fieldSeparator} /> : null}
        {field2}
        {field3 && (
          <>
            {!isMobile || noMobileWrap ? <div className={styles.fieldSeparator} /> : null}
            {field3}
          </>
        )}
      </div>
    </div>
  )
}

export default SideBySideInputs
