import React from 'react'
import { Formik } from 'formik';
import * as Yup from "yup";
import PropTypes from "prop-types";
import { todayYear } from '../../../constants';
import { useTranslation } from 'react-i18next';
import CheckoutFormFields from "../../../presenters/checkout-form-fields/CheckoutFormFields";
import FormikObserver from '../../FormikObserver';

const months = ['01','02','03','04','05','06','07','08','09','10','11','12'];
const years = [];
for (let i = todayYear; i < todayYear + 12; i++) {
    years.push(i);
}

const CheckoutForm = ({
    values,
    onFormSubmit,
    shipmentOptions,
    className,
    direction,
    labels,
    isSubmitButtonDisabled,
    setParentValues,
    hideAddress
}) => {
    const {t} = useTranslation();
    

    const checkoutValidationSchema = Yup.object().shape({
        firstName: Yup.string()
            .max(255, t('tooLong'))
            .matches(/^[^<>%$@.,/\|!&?#()_+=:;*{}]*$/, t('noCharacters'))
            .required(t('fieldIsRequired')),
        phoneNo1: Yup.string()
            .min(7, t('tooShort'))
            .max(255, t('tooLong'))
            .required(t('fieldIsRequired')),
        email: Yup.string().email(t('emailInvalid'))
            .max(255, t('tooLong'))
            .required(t('fieldIsRequired')),
        shippingMethodId: Yup.string()
            .required(t('fieldIsRequired')),
        ... !hideAddress &&
        {
            shippingAddress1: Yup.string()
                .required(t('fieldIsRequired')),
            city: Yup.string()
                .required(t('fieldIsRequired')),
            zipcode: Yup.string()
                .required(t('fieldIsRequired')),
        },
        CCNumber: Yup.string()
            // .min(12, t('tooShort'))
            .required(t('fieldIsRequired')),
        CCMonth: Yup.string()
            .required(t('fieldIsRequired')),
        CCYear: Yup.string()
            .required(t('fieldIsRequired')),
        CVV: Yup.string()
            .min(3, t('tooShort'))
            .max(4, t('tooLong'))
            .required(t('fieldIsRequired')),
        cardholderID: Yup.string()
            .required(t('fieldIsRequired'))
            .test(`id-number`, t(`idIsInvalid`),
                function isValidIsraeliID(id) {
                    return /\d{9}/.test(id) && Array.from(id, Number).reduce((counter, digit, i) => {
                        const step = digit * ((i % 2) + 1);
                        return counter + (step > 9 ? step - 9 : step);
                    }) % 10 === 0;
                })
            .max(9, t('tooLong')),
        phoneNo2: Yup.string()
            .min(7, t('tooShort')),
    })

    const onValuesChange = (values) => {
        setParentValues(values)
    }
    

    return (
        <Formik
            initialValues={values}
            onSubmit={(values) => onFormSubmit(values)}
            validationSchema={checkoutValidationSchema}
        >
            {({
                  values: formikValues,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
              }) => {
                return (
                    <form className={className} onSubmit={handleSubmit}>
                        <FormikObserver values={formikValues} onChange={values => onValuesChange(values)} />
                        <CheckoutFormFields
                            onChange={handleChange}
                            shipmentOptions={shipmentOptions}
                            errors={errors}
                            touched={touched}
                            labels={labels}
                            values={values}
                            direction={direction}
                            months={months}
                            years={years}
                            hideAddress={hideAddress}
                            isSubmitButtonDisabled={isSubmitButtonDisabled}
                        />
                    </form>
                )
            }}
        </Formik>
    )
}

CheckoutForm.propTypes = {
    className: PropTypes.string,
    // onChange: PropTypes.func.isRequired,
    // values: PropTypes.object,
    direction: PropTypes.oneOf(['ltr', 'rtl']).isRequired,
    // errors: PropTypes.object.isRequired,
    // touched: PropTypes.object.isRequired,
    labels: PropTypes.object.isRequired,
    // shipmentOptions: PropTypes.arrayOf(
    //     PropTypes.shape({
    //         name: PropTypes.string.isRequired,
    //         value: PropTypes.string.isRequired,
    //     }),
    // ).isRequired,

}

CheckoutForm.defaultProps = {
    className: '',
    values: {},
}

export default CheckoutForm
