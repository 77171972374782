import React, { useContext } from 'react'
import styles from './CartModal.module.scss'
import clsx from "clsx";
import get from "lodash/get";
import { WebsiteSettingsContext } from "../../contexts/WebsiteSettingsContext";
import { $cart, $cartIsModalOpen, closeCart } from "../../services/cart";
import { useStore } from "effector-react";
import CartModalFrame from "../../presenters/cart-modal-frame/CartModalFrame";
import CartItemsController from "../../components/cart-items-controller/CartItemsController";

const CartModal = () => {
    const { settings, direction } = useContext(WebsiteSettingsContext)
    const cart = useStore($cart)
    const cartIsModalOpen = useStore($cartIsModalOpen)
    const labels = {
        WebContinueShopping: get(settings, 'langText.WebContinueShopping') || "?",
        WebPayment: get(settings, 'langText.WebPayment') || "?",
        WebTotal: get(settings, 'langText.WebTotal') || "?",
        WebProductsQty: get(settings, 'langText.WebProductsQty') || "?",
        currencySymbol: get(settings, 'curranySimbol') || "?",
    }

    // TODO Maximum quantity: first call get product details service and then call getMaxAvailableUnits - ???

    return (
        <>
            <div className={clsx(styles.cartModal, { [styles.cartModal__isOpen]: cartIsModalOpen }, styles[direction])}>
                <CartModalFrame
                    items={cart.items}
                    labels={labels}
                    onBackToShopping={closeCart}
                    direction={direction}
                    cartItemsSlot={<CartItemsController />}
                />
            </div>
            {
                cartIsModalOpen && <div className={styles.backdrop} onClick={closeCart} />
            }
        </>
    )
}

export default CartModal
