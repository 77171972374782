import React from 'react'
import styles from './SuccessMessage.module.scss';
import ErrorIcon from '../../images/error.png'
import { useTranslation } from 'react-i18next';

const SuccessMessage = ({icon, message, isFormFailed, backToForm, iconWidth, errorMessage}) => {
  const {t} = useTranslation();
  const finalErrorMessage = errorMessage ? errorMessage : t('somethingWentWrongMessage');
  return (
    <div className={styles.container}>
      {icon && <div className={styles.iconContainer}>
        <img src={isFormFailed ? ErrorIcon : icon} alt={icon} style={{width: iconWidth}} />
      </div>}
      <div className={styles.message}>{isFormFailed ? finalErrorMessage : message}</div>
      {isFormFailed && <button onClick={backToForm} className={styles.blueBtn}>{t('tryAgain')}</button>}
    </div>
  )
}

export default SuccessMessage
