import React, { useEffect, useContext, useState } from 'react'
import styles from './CheckoutController.module.scss'
import { isObjEmpty } from '../../../utilsFunctions';
import { startLoading, stopLoading, replaceLoaders } from '../../../services/loader';
import methods from '../../../api/methods';
import { WebsiteSettingsContext } from '../../../contexts/WebsiteSettingsContext';
import { useTranslation } from 'react-i18next';
import CheckoutForm from '../../forms/checkout-form/CheckoutForm';
import clsx from 'clsx';
import CartTotals from '../../../presenters/cart-totals/CartTotals';
import { $cart, getTotalItemsCount, removeItemsByProductOrderIds } from '../../../services/cart';
import { useStore } from 'effector-react';
import CartItemsController from "../../cart-items-controller/CartItemsController";
import {formatNumber} from '../../../utilsFunctions'
import NanoLoader from '../../NanoLoader';
import SuccessMessage from '../../success-message/SuccessMessage';

const CheckoutController = () => {
    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        phoneNo1: '',
        phoneNo2: '',
        email: '',
        shippingMethodId: '',
        shippingAddress1: '',
        shippingAddress2: '',
        city: '',
        state: '',
        zipcode: '',
        CVV: '',
        CCNumber: '',
        CCMonth: '',
        CCYear: '',
        cardholderID: '',
    })
    const [pickupFromStoreId, setPickupFromStoreId] = useState(null);
    const [isFormSent, setIsFormSent] = useState(false);
    const [isFormFailed, setIsFormFailed] = useState(false);
    const [isFormLoading, setFormLoading] = useState(false);
    const [receiptNum, setReceiptNum] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [shipmentOptions, setShipmentOptions] = useState([]);
    const [productsTotal, setProductsTotal] = useState(0);
    const {t} = useTranslation();
    const cart = useStore($cart);
    const {settings, direction} = useContext(WebsiteSettingsContext);
    const {langText} = settings;
    const currentShippingMethod = shipmentOptions && shipmentOptions.filter(option => option.id === values.shippingMethodId || option.id.toString() === values.shippingMethodId)[0];
    const shippingPrice = currentShippingMethod ? currentShippingMethod.price : 0;
    const totalForPayment = productsTotal + shippingPrice;
    const curranySimbol = settings && settings.curranySimbol ? settings.curranySimbol : '';
    const totalItemsCount = getTotalItemsCount(cart.items)
    const isSubmitButtonDisabled = totalItemsCount < 1;

    const isFreeShipping = values.shippingMethodId === pickupFromStoreId;

    const getIndicesOf = (searchStr, str, caseSensitive) => {
        let searchStrLen = searchStr.length;
        if (searchStrLen === 0) {
            return [];
        }
        let startIndex = 0, index, indices = [];
        if (!caseSensitive) {
            str = str.toLowerCase();
            searchStr = searchStr.toLowerCase();
        }
        while ((index = str.indexOf(searchStr, startIndex)) > -1) {
            indices.push(index);
            startIndex = index + searchStrLen;
        }
        return indices;
    }

    const injectVariablesToString = (string, variables) => {
        const indexesOfSymbol = getIndicesOf('%', string);
        if (indexesOfSymbol.length > 2) {
            return string.slice(0, indexesOfSymbol[0]) + 
            variables[0] + 
            string.slice(indexesOfSymbol[1] + 1, indexesOfSymbol[2]) + 
            variables[1] + 
            string.slice(indexesOfSymbol[3] + 1)
        }
        return string.slice(0, indexesOfSymbol[0]) + variables[0] + string.slice(indexesOfSymbol[1] + 1)
    }

    const formattedConfirmationLine1 = langText && langText.WebOrderConfirmationLine1 && injectVariablesToString(langText.WebOrderConfirmationLine1, [values.firstName]);
    const formattedConfirmationLine2 = langText && langText.WebOrderConfirmationLine2;
    const formattedConfirmationLine3 = langText && langText.WebOrderConfirmationLine3 && injectVariablesToString(langText.WebOrderConfirmationLine3, [receiptNum, values.email]);
    const checkoutSuccessMessage = 
    formattedConfirmationLine1 +
    ' ' +
    formattedConfirmationLine2 +
    ' ' +
    formattedConfirmationLine3;

    const labels = {
        payment: langText && langText.WebPayment ? langText.WebPayment : '?',
        shippingAddress: langText && langText.WebShippingAddress ? langText.WebShippingAddress : '?',
        shippingMethod: langText && langText.WebShippingMethod ? langText.WebShippingMethod : '?',
        contactInformation: langText && langText.WebContactInformation ? langText.WebContactInformation : '?',
        firstName: langText && langText.WebFirstName ? langText.WebFirstName : '?',
        lastName: langText && langText.WebLastName ? langText.WebLastName : '?',
        email: langText && langText.WebEmail ? langText.WebEmail : '?',
        phoneNo1: langText && langText.WebPhone ? langText.WebPhone : '?',
        phoneNo2: langText && langText.WebAdditionalPhoneNo ? langText.WebAdditionalPhoneNo : '?',
        shippingMethodId: langText && langText.WebFirstName ? langText.WebFirstName : '?',
        shippingPrice: langText && langText.WebFirstName ? langText.WebFirstName : '?',
        shippingAddress1: langText && langText.WebAddress ? langText.WebAddress : '?',
        shippingAddress2: langText && langText.WebAddress ? langText.WebAddress : '?',
        city: langText && langText.WebCity ? langText.WebCity : '?',
        state: langText && langText.WebState ? langText.WebState : '?',
        zipcode: langText && langText.WebZipcode ? langText.WebZipcode : t('zipCode'),
        CVV: 'CVV',
        CCNumber: langText && langText.WebCCNo ? langText.WebCCNo : t('creditCardNumber'),
        CCMonth: 'MM',
        CCYear: 'YYYY',
        cardholderID: langText && langText.WebCCNo ? langText.WebCCNo : t('cardholderID'),
        free: langText && langText.WebFree ? langText.WebFree : t('free'),
        shipping: langText && langText.WebShipping ? langText.WebShipping : '?',
        totalForPayment: langText && langText.WebTotalForPayment ? langText.WebTotalForPayment : '?',
        confirm: langText && langText.WebConfirm ? langText.WebConfirm : '?',
    }

    const shippingInfo = [
        { name: labels.shipping, value: curranySimbol + formatNumber(shippingPrice, { addDecimals: true }) },
    ]

    const totalForPaymentInfo = [
        { name: labels.totalForPayment, value: totalForPayment ? curranySimbol + formatNumber(totalForPayment, { addDecimals: true }) : ''},
    ]

    const payload = {
        website: settings.websiteId,
    }

    useEffect(() => {
        let mounted = true;
        if (!isObjEmpty(settings)) {
            startLoading({loaders: ['shipmentOptions']});
            methods.getShipmentOptions(payload)
                .then(res => {
                    mounted && setShipmentOptions(res.data.deliveryOptions)
                })
                .finally(() => {
                    stopLoading({loaders: ['shipmentOptions']});
                })
        }
        return () => {
            replaceLoaders({loaders: []})
            mounted = false;
        }
    }, [settings])

    useEffect(() => {
        const pickupFromStoreOption = shipmentOptions
        .filter(option => option.isPickup)[0];

        if (pickupFromStoreOption) {
            setPickupFromStoreId(pickupFromStoreOption.id.toString());
            setValues({
                ...values,
                shippingMethodId: pickupFromStoreOption.id.toString(),
            })
        }
    }, [shipmentOptions])

    useEffect(() => {
        let finalTotal = 0;
        cart.items.forEach(item => {
            let productTotal = item.qty * item.product.onlinePrice;
            finalTotal += productTotal;
        })
        setProductsTotal(finalTotal)
    }, [cart])

    const formatShipmentOptions = () => {
        return shipmentOptions && shipmentOptions.map(option => {
            return {
                name: !option.price ? `${option.name} (${labels.free})` : `${option.name} (${option.currencySymbol}${option.price})`,
                value: option.id.toString(),
            }
        })
    }

    const createNewOrderUniqueID = () => {
        let dt = new Date();
        let timeStamp = dt.getTime();
        let cashRegisterID = localStorage.getItem("cashier");
        if (!cashRegisterID) {
            cashRegisterID = 0;
        }
        let sessionID = localStorage.getItem("sessionID");
        if (!sessionID) {
            sessionID = 0;
        }
        let uniqueOrderID = "o" + cashRegisterID + sessionID + timeStamp;
        return (uniqueOrderID);
    }

    const createNewTransactionUniqueID = () => {
        let dt = new Date();
        let timeStamp = dt.getTime();
        let cashRegisterID = localStorage.getItem("cashier");
        if (!cashRegisterID) {
            cashRegisterID = 0;
        }
        let sessionID = localStorage.getItem("sessionID");
        if (!sessionID) {
            sessionID = 0;
        }
        let uniqueTransactionID = "t" + cashRegisterID + sessionID + timeStamp;
        return uniqueTransactionID;
    }

    const getFormattedProductsList = (products) => {
        return products.map(({qty, product}) => {
            if (qty < 1) return null;
            return {
            "pd_id": product.pd_id,
            "pd_name": product.pdName,
            "qty": qty,
            "pd_taxRate": product.tax,
            "price": product.isOnSale ? product.onlineSalePrice : product.onlinePrice
            }
        }).filter(i => i !== null);
    }

    const onFormSubmit = (values) => {
        const valuesCopy = JSON.parse(JSON.stringify(values));

        const paymentFields = ['CCNumber', 'CCMonth', 'CCYear', 'CVV', 'cardholderID'];
        const paymentObject = {};

        paymentFields.forEach(field => {
            if (valuesCopy[field]) {
                if (field === 'CCYear') {
                    paymentObject[field] = valuesCopy[field].toString().substring(2);
                    delete valuesCopy[field];
                } else {
                    paymentObject[field] = valuesCopy[field].toString();
                    delete valuesCopy[field];
                }
            }
        })

        const alreadyOrederedProductsIds = cart.items.map(({qty, productOrderId}) => {
            if (qty < 1) return null;
            return productOrderId;
        }).filter(i => i !== null);

        const currentDate = new Date();

        const orderLocalDate = currentDate.getFullYear()
        + "-"
        + (currentDate.getMonth() + 1 ).toString().padStart(2,"0")
        + "-"
        + currentDate.getDate().toString().padStart(2,"0")
        + " "
        + currentDate.getHours().toString().padStart(2,"0")
        + ":"
        + currentDate.getMinutes().toString().padStart(2,"0")
        + ":"
        + currentDate.getSeconds().toString().padStart(3,"0");

        const finalValues = {
            order: {
                ...valuesCopy,
                website: settings && settings.websiteId.toString(),
                orderUniqueId: createNewOrderUniqueID(),
                orderLocalDate, // previously was adding new Date() here
                total: Number(totalForPayment),
                cartProducts: getFormattedProductsList(cart.items),
                shippingPrice: Number(formatNumber(shippingPrice, {addDecimals: true})),
                shippingMethodId: Number(valuesCopy.shippingMethodId),
                payments: [{
                    uniqueTransactionId: createNewTransactionUniqueID(),
                    type: 'CC',
                    amount: totalForPayment,
                    ...paymentObject,
                }],
            }
        }

        if (!isObjEmpty(settings)) {
            setFormLoading(true);
            methods.createOrder(finalValues)
                .then(response => {
                    const data = typeof response.data === 'string' ? 
                    JSON.parse(response.data) :
                    response.data;
                    if (data.errorMessage || isObjEmpty(data)) {
                        console.log(data.errorMessage)
                        setErrorMessage(data.errorMessage)
                        setIsFormFailed(true);
                    } else {
                        setReceiptNum(data.receiptNum); 
                        setIsFormSent(true);
                        removeItemsByProductOrderIds(alreadyOrederedProductsIds);
                    }
                })
                .catch(err => {
                    console.error(err)
                    setIsFormFailed(true);
                })
                .finally(() => {
                    setFormLoading(false)
                })
        } 
    }

    const backToForm = () => {
        setIsFormFailed(false);
        setIsFormSent(false);
    }

    const renderForm = () => {
        if (isFormSent) {
            return (
              <SuccessMessage message={checkoutSuccessMessage} isFormFailed={isFormFailed} />
            )
          }
        
          if (isFormFailed) {
            return (
              <SuccessMessage errorMessage={errorMessage} isFormFailed={isFormFailed} backToForm={backToForm} />
            )
          }
        return (
            <NanoLoader isLoading={isFormLoading}>
              <CheckoutForm
                shipmentOptions={formatShipmentOptions()}
                labels={labels}
                direction={direction}
                values={values}
                onFormSubmit={onFormSubmit}
                isSubmitButtonDisabled={isSubmitButtonDisabled}
                setParentValues={setValues}
                hideAddress={isFreeShipping}
              />
            </NanoLoader>
        )
    }

    return (
      <div className={clsx(styles.checkout, styles[direction])}>
          <div className={styles.summary}>
              <CartItemsController className={styles.cartItems} />
              {cart.items.length > 0 && <CartTotals items={shippingInfo} totals={totalForPaymentInfo} direction={direction} />}
          </div>
          <div className={styles.form}>
            {renderForm()}
          </div>
      </div>
  )
}

export default CheckoutController
