import React from 'react';
import styles from './Image.module.scss';
import clsx from 'clsx';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Image = ({ src, className, containerClassName, ...props }) => {
  if (!src.includes('http')) {
    return (
      <img src={src} {...props}></img>
    )
  }

  return (
    <LazyLoadImage
      src={src}
      effect="blur"
      className={clsx(className, styles.image)}
      wrapperClassName={clsx(containerClassName, styles.container)}
      {...props}
    />
  )
}

export default Image
