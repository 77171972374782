import React, { useEffect, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import { privacyPolicyText } from '../../mockData/mockData'
import styles from './PrivacyPolicy.module.scss'
import { isObjEmpty } from '../../utilsFunctions'
import methods from '../../api/methods'
import { WebsiteSettingsContext } from '../../contexts/WebsiteSettingsContext'
import clsx from 'clsx'
import { startLoading, replaceLoaders, stopLoading } from '../../services/loader'

const PrivacyPolicy = () => {
  const {t} = useTranslation()
  const {settings, direction} = useContext(WebsiteSettingsContext);
  const [privacyPolicyText, setPrivacyPolicyText] = useState('');
  const {showPrivacyPolicy} = settings;

  const payload = {
    website: settings.websiteId,
  }

  useEffect(() => {
    let mounted = true;
    if (!isObjEmpty(settings)) {
      startLoading('privacyPolicy')
      methods.getPrivacyPolicyData(payload)
      .then(res => {
        const data = res.data;
        mounted && setPrivacyPolicyText(data.text);
      })
      .finally(() => stopLoading({loaders: ['privacyPolicy']}))
    }

    return () => {
      replaceLoaders({loaders: []})
      mounted = false;
    }
  }, [settings])

  if (!showPrivacyPolicy) {
    return null;
  }
  return (
    <div className={clsx(styles[direction], styles.container)}>
      <h1 className={styles.bigTitle}>{t('privacyPolicy')}</h1>
      <p>{privacyPolicyText}</p>
    </div>
  )
}

export default PrivacyPolicy
