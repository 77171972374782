import React, { useContext } from 'react'
import styles from './ProductCard.module.scss'
import { Link } from 'react-router-dom'
import {formatImagePath, formatNumber} from '../../utilsFunctions';
import { WebsiteSettingsContext } from '../../contexts/WebsiteSettingsContext';
import clsx from 'clsx';
import Image from '../image/Image';
import LabelNew from '../label-new/LabelNew';
import { parentPath } from '../../App';

const ProductCard = ({ product, direction }) => {
  const {settings} = useContext(WebsiteSettingsContext);

  const price = product.isOnSale ? product.pdSalePrice : product.pdPrice;

  const formattedPrice = formatNumber(price, { addDecimals: true });
  return (
    <Link to={`${parentPath}/${product.pdSku}/${product.pdUrlName}`}>
      <div className={clsx(styles[direction], styles.product)}>
        <div className={styles.imageWrapper}>
          <Image
            src={formatImagePath(product.pdImg, 'product')}
            alt={product.pdName}
            className={styles.productImg}
            containerClassName={styles.productImgContainer}
          />
         {product.isNew ? <div className={styles.newLabelContainer}><LabelNew /></div> : null}
        </div>
        <div className={styles.productName}>{product.pdName}</div>
        <div className={styles.productPrice}>{settings.curranySimbol}{formattedPrice}</div>
      </div>
    </Link>
  )
}

export default ProductCard
