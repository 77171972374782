import React from 'react'
import styles from './Select.module.scss'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx';
import PropTypes from "prop-types";

const Select = ({
  error = '', 
  name, 
  value = '', 
  onChange, 
  children, 
  isError,
  isTouched,
  notSelectedLabel,
  label,
  noEmptyOption,
  direction,
  theme,
}) => {
  const {t} = useTranslation();
  const finalNotSelectedLabel = notSelectedLabel ? notSelectedLabel : t('selectATopic')
  return (
    <div className={clsx(styles[direction], styles.container, styles[theme])}>
      <div className={styles.selectLabelContainer}>
        {label && <label className={styles.label}>{label}</label>}
        <select onChange={onChange} className={clsx({[styles.withLabel]: label}, styles.input)} name={name} value={value}>
          {!noEmptyOption && <option value="" disabled>{finalNotSelectedLabel}</option>}
          {children}
        </select>
      </div>
      <div className={styles.validation}>{isError && isTouched && error}</div>
    </div>
  )
}

Select.propTypes = {
  theme: PropTypes.oneOf(['', 'checkout']),
}

Select.defaultProps = {
    theme: '',
}

export default Select
