const globalScroll = {
    lock() {
        const html = window.document.querySelector('html')
        html.scrollTo(0, 0)
        html.style.overflow = "hidden"
    },
    unLock() {
        const html = window.document.querySelector('html')
        html.style.overflow = "auto"
    },
}

export default globalScroll;
