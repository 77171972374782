export default {
  catalog: 'קטלוג',
  about: 'אודות',
  contactUs: 'צור קשר',
  membersClub: 'מועדון לקוחות',
  forMoreInfoCall: 'למידע נוסף התקשרו',
  selectedItems: 'פריטים נבחרים',
  privacyPolicy: 'מדיניות הפרטיות',
  termsOfUse: 'תנאי השימוש',
  selectATopic: 'בחר נושא',
  fieldIsRequired: 'שדה נדרש.',
  message: 'הוֹדָעָה',
  subject: 'נושא',
  name: 'שֵׁם',
  email: 'אימייל',
  address: 'כתובת',
  phoneNumber: 'מספר טלפון',
  send: 'שלח',
  emailInvalid: 'האימייל לא תקף.',
  main: 'ראשי',
  productCode: 'קוד מוצר',
  postalCode: 'מיקוד',
  gender: 'מין',
  firstName: 'שם פרטי',
  lastName: 'שם משפחה',
  birthday: 'יום הולדת',
  requiredFields: 'שדות חובה',
  country: 'מדינה',
  city: 'עִיר',
  somethingWentWrongMessage: 'שמהו השתבש, בבקשה נסה שוב!',
  tryAgain: 'נסה שוב',
  tooLong: 'יותר מדי זמן!',
  tooShort: 'קצר מדי!',
  emptyCart: 'העגלה שלך ריקה',
  zipCode: 'מיקוד',
  new: 'חדש',
  creditCardNumber: 'מספר כרטיס אשראי',
  cardholderID: 'תעודת זהות בעל כרטיס',
  free: 'חינם',
  soldOut: 'נמכר',
  checkout: {
    firstName: 'שם פרטי',
    lastName: 'שם משפחה',
    phone: 'מספר טלפון',
    phone2: 'מספר טלפון נוסף',
    email: 'אימייל',
    address1: 'כתובת 1',
    address2: 'כתובת 2',
    city: 'עִיר',
    state: 'מדינה',
    ccNumber: 'מספר כרטיס אשראי',
    ccExpirationMonth: 'MM',
    ccExpirationYear: 'YYYY',
    ccCvv: 'CVV',
    ccId: 'מספר תעודת זהות',
    contactInformation: 'פרטי התקשרות',
    shippingMethod: 'שיטת משלוח',
    shippingAddress: 'כתובת למשלוח',
    payment: 'תַשְׁלוּם',
    confirm: 'לְאַשֵׁר',
  },
  idIsInvalid: 'המזהה אינו חוקי.',
  noCharacters: 'אסור שהשדה יכלול תווים מיוחדים.',
}
