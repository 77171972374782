import React from 'react'
import styles from './CartItems.module.scss'
import { formatNumber, formatImagePath } from "../../utilsFunctions";
import PropTypes from "prop-types";
import QtySelect from "../qty-select/QtySelect";
import clsx from 'clsx';
import Image from "../../components/image/Image";

const CartItems = ({ className, items, labels, onRemoveItem, onChangeQty, direction, getMaxAvailableUnits }) => {
    if (!items || items.length === 0) {
        return (
            <div className={clsx(styles.cartEmpty, className)}>{labels.emptyCart}</div>
        )
    }

    const onIncrement = (maxAvailableUnits, product) => {
        product.qty + 1 > maxAvailableUnits 
        ? onChangeQty({ productOrderId: product.productOrderId, qty: maxAvailableUnits })
        : onChangeQty({ productOrderId: product.productOrderId, qty: product.qty + 1 });
    }

    const onChange = (maxAvailableUnits, qty, productOrderId) => {
        qty > maxAvailableUnits ? onChangeQty({ productOrderId, qty: maxAvailableUnits }) : onChangeQty({ productOrderId, qty });
    }
    
    
    return (
        <div className={className}>
            {
                items.map((item, index) => {
                    const availableQty = item.product.availableQty;
                    const allowPresale = item.product.allowPresale;
                    const maxAvailableUnits = getMaxAvailableUnits(availableQty, allowPresale)
                    return (
                    <div key={index} className={clsx(styles[direction], styles.lineItem)}>
                        <div className={styles.imgCol}>
                            <Image src={formatImagePath(item.product.pdImg, 'product')} alt={item.product.pdName} />
                        </div>
                        <div className={styles.qtyCol}>
                            <div className={styles.productName}>{item.product.pdName}</div>
                            <div>{labels.WebPrice}: {labels.currencySymbol}{formatNumber(item.product.onlinePrice, { addDecimals: true })}</div>
                            <QtySelect
                                className={styles.counter}
                                theme="sm"
                                counter={item.qty}
                                onIncrement={() => onIncrement(maxAvailableUnits, item)}
                                onDecrement={() => onChangeQty({ productOrderId: item.productOrderId, qty: item.qty - 1 })}
                                onChange={(qty) => onChange(maxAvailableUnits, qty, item.productOrderId)}
                            />
                        </div>
                        <div className={styles.actionsCol}>
                            <div className={styles.itemTotal}>{labels.currencySymbol}{formatNumber(item.product.onlinePrice * item.qty, { addDecimals: true })}</div>
                            <span className={styles.removeButton} tabIndex={0} role="button" onClick={() => onRemoveItem({ index })}>{labels.WebCartRemove}</span>
                        </div>
                    </div>
                )})
            }
        </div>
    )
    
}

CartItems.propTypes = {
    direction: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    labels: PropTypes.object.isRequired,
    onRemoveItem: PropTypes.func.isRequired,
    onChangeQty: PropTypes.func.isRequired,
}

export default CartItems
