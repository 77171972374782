import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './locales/en/translation';
import translationHE from './locales/he/translation';

const resources = {
  en: {
    translation: translationEN
  },
  he: {
    translation: translationHE
  }
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: window.location.search.includes('lang=en') ? "en" : "he",
    fallbackLng: 'he',
    debug: true,

    interpolation: {
      escapeValue: false,
    }
  });


export default i18n;