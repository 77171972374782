import React, { useEffect, useState, useContext } from 'react'
import TwoSidedPage from '../../components/pages-layouts/two-sided-page/TwoSidedPage'
import methods from '../../api/methods';
import { WebsiteSettingsContext } from '../../contexts/WebsiteSettingsContext';
import { isObjEmpty } from '../../utilsFunctions';
import MemberForm from '../../components/forms/member-form/MemberForm';
import { startLoading, stopLoading, replaceLoaders } from '../../services/loader';

const MemberPage = () => {
  const [memberData, setMemberData] = useState([]);
  const {settings, direction} = useContext(WebsiteSettingsContext);

  const payload = {
    website: settings.websiteId,
  }

  useEffect(() => {
    let mounted = true;
    if (!isObjEmpty(settings)) {
      startLoading({loaders: ['member']});
      methods.getMemberClubData(payload)
      .then(res => {
        mounted && setMemberData(res.data)
      })
      .finally(() => {
        stopLoading({loaders: ['member']});
      })
    }

    return () => {
      replaceLoaders({loaders: []})
      mounted = false;
    }
  }, [settings])

  const formSection = (
    <MemberForm memberData={memberData} />
  )

  return (
    <div>
      <TwoSidedPage direction={direction} pageHeading={settings.memberClubTitle} second={formSection} />
    </div>
  )
}

export default MemberPage
