import React from 'react'
import styles from './TextArea.module.scss'

const TextArea = ({error = 'Error message', name, value, onChange, placeholder, isError, isTouched}) => {
  return (
    <div className={styles.container}>
      <textarea name={name} value={value} onChange={onChange} className={styles.input} placeholder={placeholder} rows="5" />
      <div className={styles.validation}>{isError && isTouched && error}</div>
    </div>
  )
}

export default TextArea
