import React, { useContext } from 'react';
import styles from './LabelNew.module.scss';
import { useTranslation } from 'react-i18next';
import { returnLabelWithCheck } from '../../utilsFunctions';
import { WebsiteSettingsContext } from '../../contexts/WebsiteSettingsContext';

const LabelNew = () => {
  const {t} = useTranslation();
  const {settings} = useContext(WebsiteSettingsContext);
  const {langText} = settings;
  return (
    <div className={styles.container}>
      {returnLabelWithCheck(langText.WebNew, t('new'))}
    </div>
  )
}

export default LabelNew
