import React from 'react'
import styles from './Input.module.scss'
import clsx from 'clsx'
import PropTypes from "prop-types";
import { formatToNumberOnly } from '../../../utilsFunctions';

const Input = ({
  error = '',
  name,
  value,
  onChange,
  placeholder,
  isTouched,
  isEmail,
  isRequired,
  numberOnly,
  direction,
  theme,
}) => {
  const numberProps = numberOnly ?{ onInput: (e) => formatToNumberOnly(e) } : {};
  return (
    <div className={clsx(styles[direction], styles.container, styles[theme])}>
      <input {...numberProps} type={'text'} name={name} value={value} onChange={onChange} className={clsx({[styles.emailInput]: isEmail},styles.input)} placeholder={`${placeholder}${isRequired ? '*' : ''}`} />
      <div className={styles.validation}>{isTouched && error ? error : null}</div>
    </div>
  )
}

Input.propTypes = {
  theme: PropTypes.oneOf(['', 'checkout']),
  direction: PropTypes.oneOf(['ltr', 'rtl']),
}

Input.defaultProps = {
    theme: '',
    direction: 'rtl',
}

export default Input
