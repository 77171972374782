import React, { useContext } from 'react'
import styles from './BreadCrumbs.module.scss'
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { WebsiteSettingsContext } from '../../contexts/WebsiteSettingsContext';
import { parentPath } from '../../App';

const BreadCrumbs = ({breadCrumbs = []}) => {
  let { t } = useTranslation();
  let { direction } = useContext(WebsiteSettingsContext);
  return (
    <div className={clsx(styles[direction], styles.container)}>
      <NavLink to="/" className={styles.breadCrumbLink}>{t('main')} </NavLink>
      {
        breadCrumbs && breadCrumbs.length > 0 && breadCrumbs.map(bread => {
          return (
            <div className={styles.breadCrumb} key={bread.id}>
              <span>&nbsp;/&nbsp;</span>
              <NavLink to={`${parentPath}/catalog/${bread.id}`} className={styles.breadCrumbLink}>{bread.name}</NavLink>
            </div>
          )
        })
      }
    </div>
  )
}

export default BreadCrumbs
