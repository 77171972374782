export default {
  catalog: 'Catalog',
  about: 'About',
  contactUs: 'Contact Us',
  membersClub: 'Member\'s Club',
  forMoreInfoCall: 'For more information call',
  selectedItems: 'Selected Items',
  privacyPolicy: 'Privacy Policy',
  termsOfUse: 'Terms of Use',
  selectATopic: 'Select a topic',
  fieldIsRequired: 'Field is required.',
  message: 'Message',
  subject: 'Subject',
  name: 'Name',
  email: 'Email',
  address: 'Address',
  phoneNumber: 'Phone Number',
  send: 'Send',
  emailInvalid: 'The email is invalid.',
  main: 'Main',
  productCode: 'Product code',
  country: 'Country',
  gender: 'Gender',
  postalCode: 'Zip Code',
  firstName: 'First Name',
  lastName: 'Last Name',
  birthday: 'Birthday',
  requiredFields: 'Required Fields',
  city: 'City',
  somethingWentWrongMessage: 'Something went wrong, please try again!',
  tryAgain: 'Try again',
  tooLong: 'Too Long!',
  tooShort: 'Too Short!',
  emptyCart: 'You cart is empty',
  zipCode: 'Zipcode',
  new: 'New',
  creditCardNumber: 'Credit Card Number',
  cardholderID: 'Cardholder ID',
  free: 'Free',
  soldOut: 'SOLD OUT',
  checkout: {
    firstName: 'First Name',
    lastName: 'Last Name',
    phone: 'Phone No.',
    phone2: 'Additional Phone No.',
    email: 'Email',
    address1: 'Address 1',
    address2: 'Address 2',
    city: 'City',
    state: 'State',
    ccNumber: 'Credit Card Number',
    ccExpirationMonth: 'MM',
    ccExpirationYear: 'YYYY',
    ccCvv: 'CVV',
    ccId: 'ID Number',
    contactInformation: 'Contact Information',
    shippingMethod: 'Shipping Method',
    shippingAddress: 'Shipping Address',
    payment: 'Payment',
    confirm: 'Confirm',
  },
  idIsInvalid: 'ID is invalid.',
  noCharacters: 'Field must not contain special characters.'
}
