import React from 'react'
import styles from "./style.module.scss";
import PropTypes from "prop-types";
import clsx from "clsx";

const RadioInput = ({
    defaultChecked, value, name, inputId, className, theme, onChange
}) => {
    return (
        <div className={clsx(styles['ui-radio-input'], styles[theme], className)}>
            <input
                className={styles['ui-radio-input__form-control']}
                id={inputId}
                type="radio"
                name={name}
                onChange={onChange}
                checked={defaultChecked}
                value={value}
            />
            <div className={styles['ui-radio-input__placeholder']} />
        </div>
    );
    }

RadioInput.propTypes = {
    defaultChecked: PropTypes.bool,
    value: PropTypes.any,
    name: PropTypes.string,
    inputId: PropTypes.string,
    className: PropTypes.string,
    theme: PropTypes.string,
    onChange: PropTypes.func,
};

RadioInput.defaultProps = {
    defaultChecked: false,
    value: "",
    name: "",
    inputId: "",
    className: "",
    theme: "",
    onChange: () => {},
};

export default RadioInput;
