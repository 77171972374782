import './styles.css';
import React from 'react';
import PropTypes from "prop-types";

const NanoLoader = ({ className, isLoading, children, skipSpinner }) => (
    <div
        className={`nano-loader ${isLoading ? 'nano-loader--loading' : ''} ${className}`}
    >
        {children}
        <div className="nano-loader__overlay">
            {
                !skipSpinner && <div className="nano-loader__spinner" />
            }
        </div>
    </div>
);

NanoLoader.propTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    children: PropTypes.any.isRequired,
    skipSpinner: PropTypes.bool,
};

NanoLoader.defaultProps = {
    className: "",
    isLoading: false,
    skipSpinner: false,
};

export default NanoLoader;
