import React from 'react'
import styles from './CartTotals.module.scss'
import PropTypes from "prop-types";
import clsx from "clsx";

const CartTotals = ({ direction, items, totals }) => <div className={clsx(styles.cartTotals, styles[direction])}>
    {
        items.map(({ name, value }, i) => (
            <div className={styles.item} key={i}>
                <div className={styles.itemName}>{name}</div>
                <div className={styles.itemValue}>{value}</div>
            </div>
        ))
    }
    <div className={styles.divider} />
    {
        totals.map(({ name, value }, i) => (
            <div className={styles.item} key={i}>
                <div className={styles.itemName}>{name}</div>
                <div className={styles.itemValue}>{value}</div>
            </div>
        ))
    }
</div>

CartTotals.propTypes = {
    direction: PropTypes.oneOf(['rtl', 'ltr']),
    items: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        }),
    ).isRequired,
    totals: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        }),
    ).isRequired,
}

CartTotals.defaultProps = {
    direction: 'rtl',
}

export default CartTotals
