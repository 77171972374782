import { createEvent, createStore } from 'effector'

const setBreadCrumbsGlobal = createEvent()
const deleteBreadCrumb = createEvent()
const clearBreadCrumbs = createEvent()
const $breadCrumbs = createStore([])
    .on(setBreadCrumbsGlobal, (s, { breadCrumbs }) => {
      return breadCrumbs.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
    })
    .on(deleteBreadCrumb, (s, id) => s.filter(existing => existing.id !== id))
    .on(clearBreadCrumbs, () => [])

export {
  $breadCrumbs,
  setBreadCrumbsGlobal,
  deleteBreadCrumb,
  clearBreadCrumbs
}
