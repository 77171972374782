import React, { useContext, useEffect, useState } from 'react'
import styles from './TermsOfUse.module.scss'
import { useTranslation } from 'react-i18next'
// import { termsOfUseText } from '../../mockData/mockData'
import methods from '../../api/methods'
import { WebsiteSettingsContext } from '../../contexts/WebsiteSettingsContext'
import { isObjEmpty } from '../../utilsFunctions'
import clsx from 'clsx'
import { replaceLoaders, startLoading, stopLoading } from '../../services/loader'
  
const TermsOfUse = () => {
  const {t} = useTranslation()
  const [termsOfUseText, setTermsOfUseText] = useState('');
  const {settings, direction} = useContext(WebsiteSettingsContext);
  const {showTermsOfUse} = settings;

  const payload = {
    website: settings.websiteId,
  }

  useEffect(() => {
    let mounted = true;
    if (!isObjEmpty(settings)) {
      startLoading('termsOfUseText')
      methods.getTermsOfUseData(payload)
      .then(res => {
        const data = res.data;
        mounted && setTermsOfUseText(data.text);
      })
      .finally(() => stopLoading({loaders: ['termsOfUseText']}))
    }

    return () => {
      replaceLoaders({loaders: []})
      mounted = false;
    }
  }, [settings])

  if (!showTermsOfUse) {
    return null;
  }
  return (
    <div className={clsx(styles[direction],styles.container)}>
      <h1 className={styles.bigTitle}>{t('termsOfUse')}</h1>
      <p>{termsOfUseText}</p>
    </div>
  )
}

export default TermsOfUse
  