import React from 'react'
import styles from './CartModalFrame.module.scss'
import { formatNumber } from "../../utilsFunctions";
import { getTotalItemsCount, getTotalItemsAmount } from "../../services/cart";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import { parentPath } from '../../App';

const CartModalFrame = ({ items, labels, onBackToShopping, direction, cartItemsSlot }) => {
    const totalItemsCount = getTotalItemsCount(items)
    const totalItemsAmount = getTotalItemsAmount(items)

    return (
        <div className={styles[direction]}>
            <div className={styles.header} onClick={onBackToShopping}>
                {labels.WebContinueShopping}<img className={styles.headerIcon} alt="arrow" src={process.env.PUBLIC_URL + '/assets/icon_cart_arrow_left_v2.png'} />
            </div>
            <div className={styles.body}>
                {cartItemsSlot}
            </div>
            <div className={styles.submitBlock}>
                <Link to={`${parentPath}/checkout`}>
                    <button className={styles.submitButton} onClick={onBackToShopping}>
                        {labels.WebPayment}
                    </button>
                </Link>
                <div className={styles.submitTotals}>
                    <span>{labels.WebProductsQty} ({totalItemsCount} {labels.WebTotal})</span>
                    <span>{labels.currencySymbol}{formatNumber(totalItemsAmount, { addDecimals: true })}</span>
                </div>
            </div>
        </div>
    )
}

CartModalFrame.defaultProps = {
    direction: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    labels: PropTypes.object.isRequired,
    onBackToShopping: PropTypes.func.isRequired,
    cartItemsSlot: PropTypes.element.isRequired,
}


export default CartModalFrame
