export const formatImagePath = (imageUrl = '', imageType) => {
  const isWithJpg = imageUrl.includes('.jpg');
  if (isWithJpg) {
    return imageUrl;
  }
  const questionSymbolIndex = imageUrl.indexOf('?');
  let formattedString = imageUrl.substring(0, questionSymbolIndex) + '.jpg' + imageUrl.substring(questionSymbolIndex);
  if (imageType === 'about') {
    // TODO: need to figure out where to get seal size of an image
    formattedString = imageUrl.substring(0, questionSymbolIndex) + 'x649' + '.jpg' + imageUrl.substring(questionSymbolIndex);
  }
  if (imageType === 'category') {
    // TODO: need to figure out where to get seal size of an image
    formattedString = imageUrl.substring(0, questionSymbolIndex) + 'x332' + '.jpg' + imageUrl.substring(questionSymbolIndex);
  }
  if (imageType === 'product') {
    // TODO: need to figure out where to get seal size of an image
    formattedString = imageUrl.substring(0, questionSymbolIndex) + 'x256' + '.jpg' + imageUrl.substring(questionSymbolIndex);
  }
  if (imageType === 'logo') {
    // TODO: need to figure out where to get seal size of an image
    formattedString = imageUrl.substring(0, questionSymbolIndex) + 'x194' + '.jpg' + imageUrl.substring(questionSymbolIndex);
  }
  return formattedString;
}

export const formatObjectToFormData = (obj) => {
  let formData = new FormData();
  Object.keys(obj).forEach(key => {
    formData.append(key, JSON.stringify(obj[key]));
  })

  return formData
}

export const isObjEmpty = (obj) => {
  return obj && Object.keys(obj).length === 0;
}

export const formatNumber = (value, { toRound, addDecimals } = {}) => {
  let result = Number(value);
  if (toRound) {
    result = Math.round(result);
  } else {
    result = result % 1 !== 0 ? result.toFixed(2) : result;
  }
  result = result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return addDecimals && !result.includes(".") ? `${result}.00` : result;
}

export const returnLabelWithCheck = (label, backupLabel) => {
  if (!label && !backupLabel) {
    return ''
  } else if (!label && backupLabel) {
    return backupLabel;
  }

  return label;
}

export const formatToNumberOnly = (event) => {
  let value =  event.target.value || '';
  value = value.replace(/[^0-9\s\-\(\)\+)]/,'');
  event.target.value = value;
}