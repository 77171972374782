import React from 'react'
import styles from './Error.module.scss'
import PropTypes from "prop-types";
import clsx from "clsx";

const Error = ({ error, direction }) => <div className={clsx(styles.error, styles[direction])}>{error}</div>

Error.propTypes = {
    error: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['rtl', 'ltr']),
}

Error.defaultProps = {
    direction: 'rtl',
}

export default Error
