import React, { useEffect, useState, useContext } from 'react'
import styles from './OneCategoryPage.module.scss'
import { useParams } from 'react-router';
import CategoryList from '../../components/category-list/CategoryList';
import ProductsList from '../../components/products-list/ProductsList';
import { WebsiteSettingsContext } from '../../contexts/WebsiteSettingsContext';
import methods from '../../api/methods';
import { isObjEmpty } from '../../utilsFunctions';
import { startLoading, stopLoading, replaceLoaders } from '../../services/loader';
import BreadCrumbs from '../../components/breadcrumbs/BreadCrumbs';
import { setBreadCrumbsGlobal } from '../../services/breadCrumbs';
import leafImage from "../../images/leafSeparator.png"

const OneCategoryPage = () => {
  let { id } = useParams();

  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [title, setTitle] = useState([]);
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const {settings, direction} = useContext(WebsiteSettingsContext);

  const payload = {
    catId: id,
    website: settings.websiteId,
    isFeatured: 1,
  }

  const mainTitle = <span>- {title} -</span>;

  const productsTitle = !categories || categories.length < 1 ? mainTitle :
  <div>- - - &nbsp;<img alt="separator" className={styles.leafImage} src={leafImage} />&nbsp; - - -</div>;

  const formatBreadCrumbsToArray = (obj, withIdCheck = true) => {
    let pathDepth = [];
    obj && Object.keys(obj).forEach(key => {
      if (withIdCheck) {
        if (obj[key].name && obj[key].id != id) {
          pathDepth.push(obj[key]);
        }
      } else {
        if (obj[key].name) {
          pathDepth.push(obj[key]);
        }
      }
    })
    return pathDepth;
  }

  const fetchData = () => {
    startLoading({loaders: ['oneCategory']});
    return methods.getCategoriesAndProductsList(payload)
  }

  useEffect(() => {
    let mounted = true;

    if (!isObjEmpty(settings)) {
      fetchData()
      .then(res => {
        const data = res.data;
        if (mounted) {
          setCategories(data.categories)
          setProducts(data.products)
          Object.keys(data.breadCrumbs).forEach(key => {
            if (data.breadCrumbs[key].id == id) {
              setTitle(data.breadCrumbs[key].name);
            }
          })
          const pathDepth = formatBreadCrumbsToArray(data.breadCrumbs);
          setBreadCrumbsGlobal({breadCrumbs: pathDepth})
          setBreadCrumbs(pathDepth)
        }
      })
      .finally(() => stopLoading({loaders: ['oneCategory']}))
    }

    return () => {
      mounted = false;
      replaceLoaders({loaders: []})
      addMissingBreadCrumb()
    }
  }, [settings, id])

  const addMissingBreadCrumb = () => {
    fetchData()
      .then(res => {
        let pathDepth = formatBreadCrumbsToArray(res.data.breadCrumbs, false);
        setBreadCrumbsGlobal({breadCrumbs: pathDepth});
      })
      .finally(() => stopLoading({loaders: ['oneCategory']}))
  }

  return (
    <div className={styles.container}>
      <BreadCrumbs breadCrumbs={breadCrumbs} />
     {categories && categories.length > 0 && <CategoryList withBreadCrumbs direction={direction} categories={categories} title={mainTitle} />}
     {products && products.length > 0 && <ProductsList withBreadCrumbs direction={direction} products={products} title={productsTitle} />}
    </div>
  )
}

export default OneCategoryPage
