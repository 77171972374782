import React, { useEffect } from 'react'

const FormikObserver = ({values, onChange}) => {
  useEffect(() => {
    onChange(values)
  }, [Object.values(values).join(', ')])
  return null
}

export default FormikObserver
