import React from 'react'
import styles from './SubmitButton.module.scss'
import PropTypes from "prop-types";
import clsx from "clsx";

const SubmitButton = ({ children, direction, disabled, ...restProps }) => {
    return <button className={clsx(styles.submitButton, styles[direction])} disabled={disabled} {...restProps}>{children}</button>
}

SubmitButton.propTypes = {
    direction: PropTypes.oneOf(['rtl', 'ltr']),
}

SubmitButton.defaultProps = {
    direction: 'rtl',
}

export default SubmitButton
