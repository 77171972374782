import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import methods from '../api/methods';
import i18n from '../i18n';
import { startLoading, stopLoading } from '../services/loader';
export const WebsiteSettingsContext = React.createContext(null);

const setupGA = (GAid) => {
  const s = document.createElement('script');
  s.type = 'text/javascript';
  s.async = true;
  s.src = `https://www.googletagmanager.com/gtag/js?id=${GAid}`;
  let x = document.getElementsByTagName('script')[0];
  x.parentNode.insertBefore(s, x);

  window.dataLayer = window.dataLayer || [];

  function gtag() {
    window.dataLayer.push(arguments);
  }

  gtag('js', new Date());
  gtag('config', GAid);
}

const WebsiteSettingsContextProvider = ({children}) =>  {
  const [settings, setSettings] = useState({});
  const direction = settings && settings.lang !== 'he' ?  'ltr' : 'rtl';

  document.title = window.location.href;

  const fetchSettings = () => {
    startLoading({loaders: ['settingsContext']});
    methods.getWebsiteSettings()
    .then(res => {
      const settings = res.data;
      if (window.location.search.includes('lang=en')) {
        settings.lang = "en"
      }
        i18n.changeLanguage(settings.lang);
        var nangishliLang = localStorage.getItem("nangishliLang");
        console.log("nangishliLang: " + nangishliLang);
        console.log("settings.lang: " + settings.lang);
        if (nangishliLang != settings.lang) {                    
            console.log("reloading...");
            var reload = 'https://' + window.location.hostname + '/shop';
            console.log(reload);
            window.location.href = reload;
        }
      if (!settings.isEcommerceActive) {
        window.location.href = 'https://www.getpose.com/';
      }
      setSettings(settings)
      setupGA(settings.googleUniqueId)
    })
    .finally(() => stopLoading({loaders: ['settingsContext']}))
    // setSettings(settingsMockData);
  }

  const getSessionId = () => {
    startLoading({loaders: ['getSessionId']});
    methods.getSessionId()
    .then(res => {
      localStorage.setItem('sessionID', JSON.stringify(res.data))
    })
    .finally(() => stopLoading({loaders: ['getSessionId']}))
    // setSettings(settingsMockData);
  }

  useEffect(() => {
    fetchSettings();
    getSessionId();
  },[]);

    return (
      <WebsiteSettingsContext.Provider
          value={{
            settings,
            direction,
            isContactDisabled: settings.showContactUs === 0,
            isMemberClubDisabled: settings.showMemberClub === 0,
            isCatalogDisabled: settings.showCatalog === 0,
            isAboutDisabled: settings.showAbout === 0,
            isTermsDisabled: settings.showTermsOfUse === 0,
            isPrivacyPolicyDisabled: settings.showPrivacyPolicy === 0,
          }}
      >
          {children}
      </WebsiteSettingsContext.Provider>
    )
}

export default withRouter(WebsiteSettingsContextProvider) ;