import React, { useContext } from 'react'
import styles from './Header.module.scss';
import { NavLink, Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { WebsiteSettingsContext } from '../../contexts/WebsiteSettingsContext';
import { formatImagePath, returnLabelWithCheck } from '../../utilsFunctions';
import { openCart, getTotalItemsCount, $cart } from "../../services/cart";
import { useStore } from "effector-react";
import clsx from 'clsx';
import Image from '../image/Image';
import { parentPath } from '../../App';
import { set } from 'lodash';

const Header = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const {settings, direction, ...context} = useContext(WebsiteSettingsContext);
  const {langText} = settings;
  const cart = useStore($cart)

  const links = [
    {
      isDisabled: context.isCatalogDisabled,
      path: `${parentPath}/catalog/main`,
      label: langText && returnLabelWithCheck(langText.WebShop, t('catalog')),
      icon: <span className={clsx(styles.icon, styles.catalogIcon)}></span>,
    }, 
    {
      isDisabled: context.isAboutDisabled,
      path: `${parentPath}/about`,
      label: langText && returnLabelWithCheck(langText.WebAbout, t('about')),
      icon: <span className={clsx(styles.icon, styles.aboutIcon)}></span>,
    }, 
    {
      isDisabled: context.isContactDisabled,
      path: `${parentPath}/contact`,
      label: langText && returnLabelWithCheck(langText.WebContact, t('contactUs')),
      icon: <span className={clsx(styles.icon, styles.contactIcon)}></span>,
    }, 
    {
      isDisabled: context.isMemberClubDisabled,
      path: `${parentPath}/member`,
      label: langText && returnLabelWithCheck(langText.WebMemberClub, t('membersClub')),
      icon: <span className={clsx(styles.icon, styles.memberIcon)}></span>,
    }
  ]
  const totalItemsCount = getTotalItemsCount(cart.items)

  const currentPathname = location.pathname;

  const cartButton = (
    <div className={styles.cart} onClick={openCart}>
      <img src={process.env.PUBLIC_URL + '/assets/icon_cart_v1.png'} alt="Cart"/>
      {
        totalItemsCount > 0 &&
        <span className={styles.cartCounter}>{totalItemsCount}</span>
      }
    </div>
  )

  const headerText = (
    <span>
      {returnLabelWithCheck(settings.headerText)}
    </span>
  )
  return (
    <div>
      <div className={styles.logoContainer}>
        <Link to="/" className="no-text-decoration">
          {settings && settings.logo && <Image src={formatImagePath(settings.logo, 'logo')} alt="logo" />}
        </Link>
      </div>
      <div className={styles.mobileInfo}>
        {headerText}
      </div>
      <div className={clsx(styles[direction], styles.navBarContainer)}>
        <div className={styles.navBarLinks}>
          {links.map(link => {
            if (link.isDisabled) {
              return null;
            }
            return (
              <NavLink
                key={link.path}
                to={link.path}
                className={styles.link}
                activeClassName={styles.activeLink}
              >
                <div className={styles.label}>{link.label}</div>
                <div className={clsx({[styles.activeIconContainer]: currentPathname === link.path}, styles.iconContainer)}>{link.icon}</div>
              </NavLink>
            )
          })}
          <div className="mobileOnly">{cartButton}</div>
        </div>
        <div className={styles.navBarInfo}>
          {headerText}
          {cartButton}
        </div>
      </div>
    </div>
  )
}

export default Header
