class LsStorage {
    constructor(namespace) {
        this.namespace = namespace
    }
    key(name) {
        return `${this.namespace}.${name}`;
    }
    save(name, value) {
        setTimeout(() => this.saveSync(name, value), 0);
    }
    saveSync(name, value) {
        localStorage.setItem(this.key(name), JSON.stringify(value));
    }
    load(name) {
        const key = this.key(name);
        const str = localStorage.getItem(key);
        let result = null;
        if (!str) {
            return result;
        }
        try {
            result = JSON.parse(str);
        } catch (e) {
            console.warn(`Cannot parse local storage value ${key}: ${str}`, e);
        }
        return result;
    }
}

export default LsStorage
