import React, { useContext } from 'react'
import CatalogMainPage from '../pages/catalog-main-page/CatalogMainPage'
import OneCategoryPage from '../pages/one-category-page/OneCategoryPage'
import AboutPage from '../pages/about-page/AboutPage'
import ContactPage from '../pages/contact-page/ContactPage'
import MemberPage from '../pages/member-page/MemberPage'
import TermsOfUse from '../pages/terms-of-use/TermsOfUse'
import PrivacyPolicy from '../pages/privacy-policy/PrivacyPolicy'
import OneProductPage from '../pages/one-product-page/OneProductPage'
import CheckoutPage from '../pages/checkout-page/CheckoutPage'
import { Route, Switch, Redirect } from 'react-router'
import { parentPath } from '../App'
import { WebsiteSettingsContext } from '../contexts/WebsiteSettingsContext'

const Routes = () => {
    const context = useContext(WebsiteSettingsContext);

    return (
        <Switch>
            <Route path={`${parentPath}/catalog`}>
                <Switch>
                <Route exact path={`${parentPath}/catalog`}>
                    <Redirect to={`${parentPath}/catalog/main`} />
                </Route>
                <Route exact path={`${parentPath}/catalog/main`}>
                    <CatalogMainPage />
                </Route>
                <Route path={`${parentPath}/catalog/:id`}>
                    <OneCategoryPage />
                </Route>
                </Switch>
            </Route>
            <Route exact path={`/`}>
                <Redirect to={`${parentPath}/catalog`} />
            </Route>
            <Route path={`${parentPath}/about`}>
                {context.isAboutDisabled ?  <Redirect to={`${parentPath}/catalog`} /> : <AboutPage /> }
            </Route>
            <Route path={`${parentPath}/contact`}>
                {context.isContactDisabled ? <Redirect to={`${parentPath}/catalog`} /> : <ContactPage />}
            </Route>
            <Route path={`${parentPath}/member`}>
                {context.isMemberClubDisabled ? <Redirect to={`${parentPath}/catalog`} /> : <MemberPage />}
            </Route>
            <Route path={`${parentPath}/terms-of-use`}>
                {context.isTermsDisabled ? <Redirect to={`${parentPath}/catalog`} /> : <TermsOfUse />}
            </Route>
            <Route path={`${parentPath}/privacy-policy`}>
                {context.isPrivacyPolicyDisabled ? <Redirect to={`${parentPath}/catalog`} /> : <PrivacyPolicy />}
            </Route>
            <Route path={`${parentPath}/checkout`} component={CheckoutPage} />
            
            <Route path={`${parentPath}/:sku/:pdName`}>
                <OneProductPage />
            </Route>

            <Route path={`${parentPath}/`}>
                <Redirect to={`${parentPath}/catalog`} />
            </Route>
        </Switch>
    )
}

export default Routes
