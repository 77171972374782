import React from 'react'
import styles from './InfoWithLabel.module.scss'
import clsx from 'clsx'

const InfoWithLabel = ({label, info}) => {
  return (
    <div className={styles.container}>
      <div className={clsx("spesialText", styles.label)}>{label}: </div>
      <div className={clsx("regularText", styles.info)}>{info}</div>
    </div>
  )
}

export default InfoWithLabel
